import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LOGIN } from '../../graphql/mutations';
import { accessTokenStorage, deleteAllTokens, refreshTokenStorage } from '../../tokenStorage';
import { GET_MY_USER } from '../../graphql/queries/UserQueries';
import useQueryParams from '../../utils/useQueryParams';
import removeEmptyStrings from '../../utils/removeEmpty';

const Login = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [login, { data: loginData, loading: loginLoading, error: errorLogin }] = useMutation(LOGIN);
  const [getMyUser, { data: getMyUserData, loading: loadingMyUser, error: errorMyUser }] =
    useLazyQuery(GET_MY_USER);

  useEffect(() => {
    if (queryParams?.get('verification-success')) {
      setSnackbarMessage('Your account has been verified');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, []);

  useEffect(() => {
    if (errorLogin) {
      setSnackbarMessage(errorLogin.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (loginData) {
      const accessToken = loginData.postAuthenticationLocal?.accessToken;
      const refreshToken = loginData.postAuthenticationLocal?.refreshToken;
      accessTokenStorage.write(accessToken);
      refreshTokenStorage.write(refreshToken);
    }
  }, [loginData, errorLogin]);

  useEffect(() => {
    if (errorMyUser) {
      setSnackbarMessage(errorMyUser.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (getMyUserData) {
      const userType =
        getMyUserData?.getCmsFunctionGetMyUser?.result?.length > 0
          ? getMyUserData?.getCmsFunctionGetMyUser?.result[0].userType
          : undefined;
      navigate(`/${userType?.toLowerCase()}/dashboard`, { replace: true });
    }
  }, [getMyUserData, errorMyUser]);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            validateOnMount
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={(values) => {
              const cleanValues = removeEmptyStrings(values);
              deleteAllTokens();
              login({
                variables: {
                  params: {
                    ...cleanValues,
                  },
                },
              }).then(() => {
                getMyUser();
              });
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={loginLoading || loadingMyUser}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/forgot-password" variant="h6">
                    Forgot your password?
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
