import React from 'react';
import { Autocomplete, Box, TextField } from '@material-ui/core';
import { countries } from 'countries-list';
import PropTypes from 'prop-types';

const CountrySelect = ({ TextFieldProps, ...rest }) => {
  return (
    <Autocomplete
      id="country-select"
      options={Object.keys(countries)}
      autoHighlight
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {countries[option]?.name} ({option})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps?.InputProps,
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...TextFieldProps?.InputLabelProps,
          }}
        />
      )}
      {...rest}
    />
  );
};

CountrySelect.propTypes = {
  TextFieldProps: PropTypes.object,
};

export default CountrySelect;
