import { gql } from '@apollo/client';

export const ADD_USER = gql`
  mutation PostCmsUserData($params: postCmsUserDataRequest) {
    postCmsUserData(params: $params) {
      _id
      createdAt
      CSN
      userType
      email
    }
  }
`;

export const EDIT_USER = gql`
  mutation PutCmsUserDataId($params: putCmsUserDataIdRequest, $id: String!) {
    putCmsUserDataId(params: $params, id: $id) {
      _id
      createdAt
      CSN
      userType
      email
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteCmsUserDataId($id: String!) {
    deleteCmsUserDataId(id: $id) {
      result
    }
  }
`;
