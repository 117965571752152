import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../../graphql/mutations';
import removeEmptyStrings from '../../utils/removeEmpty';

const ForgotPassword = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [
    forgotPassword,
    { data: forgotPasswordData, loading: forgotPasswordLoading, error: errorForgotPassword },
  ] = useMutation(FORGOT_PASSWORD);

  useEffect(() => {
    if (errorForgotPassword) {
      setSnackbarMessage(errorForgotPassword.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (forgotPasswordData) {
      setSnackbarMessage('Reset password email sent');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [forgotPasswordData, errorForgotPassword]);

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            validateOnMount
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            })}
            onSubmit={(values, formikHelpers) => {
              const cleanValues = removeEmptyStrings(values);
              forgotPassword({
                variables: {
                  params: {
                    ...cleanValues,
                  },
                },
              });
              formikHelpers.resetForm();
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 2 }}>
                  <Typography color="textPrimary" variant="h2">
                    Forgot your password?
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Fill in your email and we will send you a link to reset your password.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={forgotPasswordLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/login" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPassword;
