import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Decimal from 'decimal.js';
import ProductDetailsDialog from './ProductDetailsDialog';
import { Visibility } from '@material-ui/icons';

const ProductListResults = ({
  products,
  count,
  skip,
  setSkip,
  limit,
  setLimit,
  onBuy,
  contractTermSelected,
  ...rest
}) => {
  const [productDetails, setProductDetails] = useState(null);
  const [openProductDetails, setOpenProductDetails] = useState(false);

  const handleShowProductDetails = (event, product) => {
    if (product) {
      setProductDetails(product);
      setOpenProductDetails(true);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Material Number</TableCell>
                  <TableCell>Product Group</TableCell>
                  <TableCell>Usage Type</TableCell>
                  <TableCell>Cost Price</TableCell>
                  <TableCell>Contract Term</TableCell>
                  <TableCell padding="checkbox" />
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {products
                  ? products.slice(skip, skip + limit).map((product) => (
                      <TableRow hover key={product._id}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {product.materialNumber}
                          </Typography>
                        </TableCell>
                        <TableCell>{product.productGroup}</TableCell>
                        <TableCell>{product.usageType}</TableCell>
                        <TableCell>${product.costPrice}</TableCell>
                        <TableCell>{product.contractTerm}</TableCell>
                        <TableCell padding="checkbox">
                          <Button
                            disabled={
                              contractTermSelected
                                ? contractTermSelected !== product.contractTerm
                                : false
                            }
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{ marginRight: 2 }}
                            onClick={(event) => {
                              event.stopPropagation();
                              onBuy(product);
                            }}
                          >
                            Buy
                          </Button>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            sx={{ marginRight: 2 }}
                            onClick={(event) => handleShowProductDetails(event, product)}
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={pageNumber(skip, limit)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <ProductDetailsDialog
        setOpen={setOpenProductDetails}
        open={openProductDetails}
        product={productDetails}
        onBuy={onBuy}
      />
    </>
  );
};

ProductListResults.propTypes = {
  products: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  onBuy: PropTypes.func,
  contractTermSelected: PropTypes.string,
};

ProductListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default ProductListResults;
