import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Decimal from 'decimal.js';
import CustomerDetailsDialog from './CustomerDetailsDialog';
import { Visibility } from '@material-ui/icons';

const CustomerListResults = ({
  customers,
  count,
  skip,
  setSkip,
  limit,
  setLimit,
  setSelected,
  selected,
  updateCustomer,
  updateCustomerLoading,
  ...rest
}) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const [openCustomerDetails, setOpenCustomerDetails] = useState(false);

  const handleShowCustomerDetails = (event, customer) => {
    if (customer) {
      setCustomerDetails(customer);
      setOpenCustomerDetails(true);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {setSelected ? <TableCell padding={'checkbox'} /> : null}
                  <TableCell>Name</TableCell>
                  <TableCell>Alt Name</TableCell>
                  <TableCell>CSN</TableCell>
                  <TableCell>Registration Date</TableCell>
                  <TableCell padding={'checkbox'} />
                </TableRow>
              </TableHead>
              <TableBody>
                {customers
                  ? customers.slice(skip, skip + limit).map((customer) => (
                      <TableRow hover key={customer._id}>
                        {setSelected ? (
                          <TableCell padding={'checkbox'}>
                            <Checkbox
                              checked={customer._id === selected?._id}
                              onChange={(event) =>
                                setSelected(event.target.checked ? customer : undefined)
                              }
                              onClick={(event) => event.stopPropagation()}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {customer.account?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>{customer.account?.altName}</TableCell>
                        <TableCell>{customer.CSN}</TableCell>
                        <TableCell>{moment(customer.createdAt).format('DD/MM/YYYY')}</TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            sx={{ marginRight: 2 }}
                            onClick={(event) => handleShowCustomerDetails(event, customer)}
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={pageNumber(skip, limit)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <CustomerDetailsDialog
        setOpen={setOpenCustomerDetails}
        open={openCustomerDetails}
        customer={customerDetails}
        onSubmit={updateCustomer}
        loading={updateCustomerLoading}
      />
    </>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  updateCustomer: PropTypes.func,
  updateCustomerLoading: PropTypes.bool,
};

CustomerListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default CustomerListResults;
