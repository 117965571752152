import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Decimal from 'decimal.js';

const ContractManagerListResults = ({
  managers,
  count,
  skip,
  setSkip,
  limit,
  setLimit,
  setSelected,
  selected,
  ...rest
}) => {
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {setSelected ? <TableCell padding={'checkbox'} /> : null}
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Country Code</TableCell>
                  <TableCell>Language</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {managers
                  ? managers.slice(skip, skip + limit).map((manager) => (
                      <TableRow hover key={manager._id}>
                        {setSelected ? (
                          <TableCell padding={'checkbox'}>
                            <Checkbox
                              checked={manager._id === selected?._id}
                              onChange={(event) =>
                                setSelected(event.target.checked ? manager : undefined)
                              }
                              onClick={(event) => event.stopPropagation()}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {manager.firstName}
                          </Typography>
                        </TableCell>
                        <TableCell>{manager.lastName}</TableCell>
                        <TableCell>{manager.email}</TableCell>
                        <TableCell>{manager.countryCode}</TableCell>
                        <TableCell>{manager.language}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={pageNumber(skip, limit)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

ContractManagerListResults.propTypes = {
  managers: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

ContractManagerListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default ContractManagerListResults;
