import { gql } from '@apollo/client';

export const SYNC = gql`
  mutation PostMindwareservicesSyncOperation($syncType: String) {
    postMindwareservicesSyncOperation(syncType: $syncType) {
      result
    }
  }
`;

export const SUBMIT_PRICE_LIST = gql`
  mutation PostMindwareservicesSubmitPriceList(
    $params: postMindwareservicesSubmitPriceListRequest
  ) {
    postMindwareservicesSubmitPriceList(params: $params) {
      result
    }
  }
`;
