import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import removeEmptyStrings from '../../utils/removeEmpty';

const ResellerEditDialog = ({ open, setOpen, onSubmit, currentValues }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <Formik
        validateOnMount
        // enableReinitialize
        initialValues={{
          CSN: currentValues?.CSN ?? '',
        }}
        validationSchema={Yup.object().shape({
          CSN: Yup.string().required('CSN is required'),
        })}
        onSubmit={(values) => {
          const cleanValues = removeEmptyStrings(values);
          handleClose();
          onSubmit({ ...currentValues, ...cleanValues });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Edit Reseller</DialogTitle>
            <DialogContent>
              <DialogContentText>Change reseller&apos;s CSN.</DialogContentText>
              <TextField
                error={Boolean(touched.CSN && errors.CSN)}
                fullWidth
                helperText={touched.CSN && errors.CSN}
                label="CSN"
                margin="normal"
                name="CSN"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.CSN}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={isSubmitting} type={'submit'} color="primary" autoFocus>
                Edit
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

ResellerEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentValues: PropTypes.object,
};

export default ResellerEditDialog;
