import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Badge, Box, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './Logo';
import { ShoppingCart } from '@material-ui/icons';

const DashboardNavbar = ({ onMobileNavOpen, user, itemsInCart, goToCart, ...rest }) => {
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <Box display={{ lg: 'none' }} sx={{ marginRight: 2 }}>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {user?.userType === 'RESELLER' ? (
          <Box>
            <IconButton color="inherit" onClick={goToCart}>
              <Badge badgeContent={itemsInCart} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>
          </Box>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  user: PropTypes.object,
  itemsInCart: PropTypes.number,
  goToCart: PropTypes.func,
};

export default DashboardNavbar;
