import React, { useEffect, useLayoutEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { Backdrop, CircularProgress, ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { useQuery } from '@apollo/client';
import { GET_MY_USER } from './graphql/queries/UserQueries';
import client from './graphql/apolloClient';
import useQueryParams from './utils/useQueryParams';
import { sessionAccessTokenStorage } from './tokenStorage';

const App = () => {
  const queryParams = useQueryParams();

  useLayoutEffect(() => {
    if (queryParams?.get('session-access-token')) {
      sessionAccessTokenStorage.write(queryParams?.get('session-access-token'));
    }
  }, []);

  const { loading: loadingMyUser } = useQuery(GET_MY_USER);
  const [loggedInUserType, setLoggedInUserType] = useState(null);

  useEffect(() => {
    const querySubscription = client
      .watchQuery({
        query: GET_MY_USER,
      })
      .subscribe({
        next: ({ data }) => {
          const userType =
            data?.getCmsFunctionGetMyUser?.result?.length > 0
              ? data?.getCmsFunctionGetMyUser?.result[0]?.userType
              : null;
          setLoggedInUserType(userType);
        },
        error: () => {
          setLoggedInUserType(null);
        },
      });
    return () => {
      querySubscription.unsubscribe();
    };
  });

  const routing = useRoutes(routes(loggedInUserType));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {loadingMyUser ? <Loading /> : routing}
    </ThemeProvider>
  );
};
const Loading = () => (
  <Backdrop open>
    <CircularProgress color="primary" />
  </Backdrop>
);

export default App;
