import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import ProductListResults from '../../components/product/ProductListResults';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PRODUCT_LINES, GET_PRODUCTS } from '../../graphql/queries/ProductQueries';
import ProductListToolbar from '../../components/product/ProductListToolbar';
import { ADD_TO_BASKET } from '../../graphql/mutations/BasketMutations';
import { GET_BASKET } from '../../graphql/queries/BasketQueries';
import BasketAddDialog from '../../components/basket/BasketAddDialog';
import { useNavigate } from 'react-router-dom';
import client from '../../graphql/apolloClient';

const INIT_SKIP = 0;
const INIT_LIMIT = 10;

const ResellerProductList = () => {
  const navigate = useNavigate();
  const [skip, setSkip] = useState(INIT_SKIP);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [searchText, setSearchText] = useState(undefined);
  const [contractTermFilter, setContractTermFilter] = useState();
  const [materialGroupFilter, setMaterialGroupFilter] = useState();
  const [materialTypeFilter, setMaterialTypeFilter] = useState();
  const [productLineFilter, setProductLineFilter] = useState();
  const [usageTypeFilter, setUsageTypeFilter] = useState();
  const [multiuserFilter, setMultiuserFilter] = useState(false);
  const [productLineSearchText, setProductLineSearchText] = useState(undefined);
  const [addToBasketOpen, setAddToBasketOpen] = useState(false);
  const [productToBuy, setProductToBuy] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const { called: calledGetBasket } = useQuery(GET_BASKET);
  const basketData = client.readQuery({
    query: GET_BASKET,
  });

  const {
    data: getProductData,
    fetchMore: fetchMoreProductData,
    refetch: refetchProductData,
    called: calledProductData,
    error: errorProductData,
  } = useQuery(GET_PRODUCTS, {
    variables: {
      searchString: searchText,
      skip: INIT_SKIP,
      limit: INIT_LIMIT,
      productLine: productLineFilter,
      usageType: usageTypeFilter,
      materialGroup: materialGroupFilter,
      materialType: materialTypeFilter,
      contractTerm: contractTermFilter,
    },
  });

  const {
    data: getProductLineData,
    refetch: refetchProductLineData,
    error: errorProductLineData,
    called: calledProductLineData,
  } = useQuery(GET_PRODUCT_LINES, {
    variables: {
      skip: 0,
      limit: 10,
      searchString: productLineSearchText,
    },
  });

  const [addToBasket, { error: errorAddToBasket }] = useMutation(ADD_TO_BASKET, {
    refetchQueries: [{ query: GET_BASKET }],
  });

  useEffect(() => {
    if (calledProductData) {
      const docCount = getProductData?.getMindwareservicesProducts?.documentsCount ?? 0;
      const currentCount = getProductData?.getMindwareservicesProducts?.documents?.length ?? 0;
      const skipLimit = skip + limit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreProductData({
          variables: {
            searchString: searchText,
            skip: skip,
            limit: limit,
            productLine: productLineFilter,
            usageType: usageTypeFilter,
            materialGroup: materialGroupFilter,
            materialType: materialTypeFilter,
            contractTerm: contractTermFilter,
          },
        });
      }
    }
  }, [skip]);

  useEffect(() => {
    if (calledProductData) {
      refetchProductData({
        searchString: searchText,
        skip: skip,
        limit: limit,
        productLine: productLineFilter,
        usageType: usageTypeFilter,
        materialGroup: materialGroupFilter,
        materialType: materialTypeFilter,
        contractTerm: contractTermFilter,
        isMultiuser: multiuserFilter,
      });
    }
  }, [
    limit,
    searchText,
    productLineFilter,
    usageTypeFilter,
    materialGroupFilter,
    materialTypeFilter,
    contractTermFilter,
    multiuserFilter,
  ]);

  useEffect(() => {
    if (productLineSearchText) {
      if (calledProductLineData) {
        refetchProductLineData({
          skip: 0,
          limit: 10,
          searchString: productLineSearchText,
        });
      }
    }
  }, [productLineSearchText]);

  useEffect(() => {
    if (errorProductData) {
      setSnackbarMessage(errorProductData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorProductData]);

  useEffect(() => {
    if (errorProductLineData) {
      setSnackbarMessage(errorProductLineData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorProductLineData]);

  useEffect(() => {
    if (errorAddToBasket) {
      setSnackbarMessage(errorAddToBasket.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorAddToBasket]);

  const setBuyProduct = (product) => {
    setProductToBuy(product);
    setAddToBasketOpen(true);
  };

  const handleAddBasket = (values) => {
    const { product, quantity = 1 } = values;
    addToBasket({ variables: { product: product, quantity: quantity } });
  };

  const handleCheckoutBasket = (values) => {
    const { product, quantity = 1 } = values;
    addToBasket({ variables: { product: product, quantity: quantity } }).then(() =>
      navigate('/reseller/basket'),
    );
  };

  const handleSetLimit = (limit) => {
    setLimit(limit);
    setSkip(INIT_SKIP);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    setSkip(INIT_SKIP);
  };
  const handleSetContractTermFilter = (text) => {
    setContractTermFilter(text);
    setSkip(INIT_SKIP);
  };
  const handleSetMaterialGroupFilter = (text) => {
    setMaterialGroupFilter(text);
    setSkip(INIT_SKIP);
  };
  const handleSetMaterialTypeFilter = (text) => {
    setMaterialTypeFilter(text);
    setSkip(INIT_SKIP);
  };
  const handleSetProductLineFilter = (text) => {
    setProductLineFilter(text);
    setSkip(INIT_SKIP);
  };
  const handleSetUsageTypeFilter = (text) => {
    setUsageTypeFilter(text);
    setSkip(INIT_SKIP);
  };
  const handleSetMultiuserFilter = (value) => {
    setMultiuserFilter(value);
    setSkip(INIT_SKIP);
  };

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <ProductListToolbar
            onSearch={handleSearch}
            onSearchProductLines={setProductLineSearchText}
            productLines={getProductLineData?.getMindwareservicesProductLines?.documents ?? []}
            onSetContractTermFilter={handleSetContractTermFilter}
            onSetMaterialGroupFilter={handleSetMaterialGroupFilter}
            onSetMaterialTypeFilter={handleSetMaterialTypeFilter}
            onSetProductLineFilter={handleSetProductLineFilter}
            onSetUsageTypeFilter={handleSetUsageTypeFilter}
            onSetMultiuserFilter={handleSetMultiuserFilter}
          />
          <ProductListResults
            sx={{ mt: 2 }}
            products={getProductData?.getMindwareservicesProducts?.documents ?? []}
            count={getProductData?.getMindwareservicesProducts?.documentsCount ?? 0}
            skip={skip}
            setSkip={setSkip}
            limit={limit}
            setLimit={handleSetLimit}
            onBuy={setBuyProduct}
            contractTermSelected={
              basketData?.getMindwareservicesBasket?.products?.[0]
                ? basketData.getMindwareservicesBasket.products[0].product.contractTerm
                : undefined
            }
          />
        </Container>
      </Box>
      <BasketAddDialog
        open={addToBasketOpen}
        setOpen={setAddToBasketOpen}
        onCheckout={handleCheckoutBasket}
        onAdd={handleAddBasket}
        product={productToBuy}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResellerProductList;
