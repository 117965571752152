import { gql } from '@apollo/client';

export const CHANGE_PLATFORM_SETTINGS = gql`
  mutation PostAuthenticationLocal($params: putCmsPlatformSettingsIdRequest, $id: String!) {
    putCmsPlatformSettingsId(params: $params, id: $id) {
      _id
      margin
    }
  }
`;
