import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Chip,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from '@material-ui/core';
import { Clear, Close, Done, Visibility } from '@material-ui/icons';
import { DRAWER_WIDTH } from '../DashboardSidebar';
import { useTheme } from '@material-ui/styles';
import OrderDetailsDialog from './OrderDetailsDialog';
import ProductListResults from '../product/ProductListResults';
import Decimal from 'decimal.js';
import orderStatus, { orderStatusColors } from '../../utils/orderStatus';

const OrderListResults = ({
  orders,
  count,
  skip = 0,
  setSkip,
  limit,
  setLimit,
  onMarkPaid,
  disablePagination,
  ...rest
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const handleMarkPaid = () => {
    const idsToMark = selectedOrderIds.slice();
    onMarkPaid(idsToMark, true);
    setSelectedOrderIds([]);
  };

  const handleMarkUnpaid = () => {
    const idsToMark = selectedOrderIds.slice();
    onMarkPaid(idsToMark, false);
    setSelectedOrderIds([]);
  };

  const handleShowCategoryDetails = (event, order) => {
    if (order) {
      setOrderDetails(order);
      setOpenOrderDetails(true);
    }
  };

  const handleSelectAll = (event) => {
    let newSelectedOrderIds;

    if (event.target.checked) {
      newSelectedOrderIds = orders
        .filter((order) => orderStatus[order.status] === orderStatus.SUCCESSFUL)
        .map((order) => order._id);
    } else {
      newSelectedOrderIds = [];
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrderIds.indexOf(id);
    let newSelectedOrderIds = [];

    if (selectedIndex === -1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds, id);
    } else if (selectedIndex === 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds.slice(1));
    } else if (selectedIndex === selectedOrderIds.length - 1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, selectedIndex),
        selectedOrderIds.slice(selectedIndex + 1),
      );
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {onMarkPaid ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedOrderIds.length > 0 && selectedOrderIds.length === orders.length
                        }
                        color="primary"
                        indeterminate={
                          selectedOrderIds.length > 0 && selectedOrderIds.length < orders.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Status Message</TableCell>
                  {onMarkPaid ? <TableCell>Paid</TableCell> : null}
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {orders
                  ? orders
                      .slice(skip, skip + (!disablePagination ? limit : orders?.length))
                      .map((order) => (
                        <TableRow hover key={order._id}>
                          {onMarkPaid ? (
                            <TableCell padding="checkbox">
                              <Checkbox
                                disabled={orderStatus[order.status] !== orderStatus.SUCCESSFUL}
                                checked={selectedOrderIds.indexOf(order._id) !== -1}
                                onClick={(event) => event.stopPropagation()}
                                onChange={(event) => handleSelectOne(event, order._id)}
                                value="true"
                              />
                            </TableCell>
                          ) : null}
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {order.transactionId ?? '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>{order.customer?.account?.name}</TableCell>
                          <TableCell>${order.cost}</TableCell>
                          <TableCell>{moment(order.createdAt).format('DD/MM/YYYY')}</TableCell>
                          <TableCell>
                            <Chip
                              color="primary"
                              label={orderStatus[order.status]}
                              size="small"
                              style={{ backgroundColor: orderStatusColors[order.status] }}
                            />
                          </TableCell>
                          <TableCell>
                            {order.processingMessage}
                            {/*<Tooltip title={order.processingMessage}>*/}
                            {/*  <IconButton size="small" sx={{ marginRight: 2 }}>*/}
                            {/*    <Visibility />*/}
                            {/*  </IconButton>*/}
                            {/*</Tooltip>*/}
                          </TableCell>
                          {onMarkPaid ? (
                            <TableCell>
                              {order.paid ? <Done color={'primary'} /> : <Close color={'error'} />}
                            </TableCell>
                          ) : null}
                          <TableCell padding="checkbox">
                            <IconButton
                              size="small"
                              sx={{ marginRight: 2 }}
                              onClick={(event) => handleShowCategoryDetails(event, order)}
                            >
                              <Visibility />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {!disablePagination ? (
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={pageNumber(skip, limit)}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        ) : null}
      </Card>
      <Box
        sx={{
          margin: 0,
          top: 'auto',
          bottom: 0,
          left: matches ? DRAWER_WIDTH : 0,
          right: 0,
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 1,
          flexDirection: matches ? 'row' : 'column',
        }}
      >
        <Zoom in={selectedOrderIds?.length > 0} unmountOnExit>
          <Fab
            onClick={handleMarkPaid}
            variant="extended"
            size="small"
            color={'primary'}
            sx={{ zIndex: 9999, marginTop: 1, marginLeft: 1, marginRight: 1 }}
          >
            <Done sx={{ marginRight: 1 }} />
            Mark as paid
          </Fab>
        </Zoom>
        <Zoom in={selectedOrderIds?.length > 0} unmountOnExit>
          <Fab
            onClick={handleMarkUnpaid}
            variant="extended"
            size="small"
            sx={{ zIndex: 9999, marginTop: 1, marginLeft: 1, marginRight: 1 }}
          >
            <Clear sx={{ marginRight: 1 }} />
            Mark as unpaid
          </Fab>
        </Zoom>
      </Box>
      <OrderDetailsDialog
        setOpen={setOpenOrderDetails}
        open={openOrderDetails}
        order={orderDetails}
      />
    </>
  );
};

OrderListResults.propTypes = {
  orders: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  onMarkPaid: PropTypes.func,
  disablePagination: PropTypes.bool,
};

ProductListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default OrderListResults;
