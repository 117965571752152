import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const BasketContractRadio = ({ disabled, existing, setExisting, ...rest }) => {
  useEffect(() => {
    if (disabled) {
      setExisting(false);
    }
  }, [disabled]);

  return (
    <Card {...rest}>
      <CardContent>
        <FormControl component="fieldset">
          <RadioGroup
            row
            defaultValue={'new'}
            value={disabled ? 'new' : existing ? 'existing' : 'new'}
            onChange={(event, value) => setExisting(value === 'existing')}
          >
            <FormControlLabel value="new" control={<Radio />} label="New Contract" />
            <FormControlLabel
              disabled={disabled ?? false}
              value="existing"
              control={<Radio />}
              label="Existing Contract"
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};

BasketContractRadio.propTypes = {
  disabled: PropTypes.bool,
  existing: PropTypes.bool.isRequired,
  setExisting: PropTypes.func.isRequired,
};

export default BasketContractRadio;
