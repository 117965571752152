import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const ContractManagerListToolbar = ({ onSearch, search, ...rest }) => {
  const onChangeSearch = useMemo(
    () =>
      debounce((event) => {
        onSearch(event?.target?.value);
      }, 500),
    [onSearch],
  );

  return (
    <Box {...rest}>
      <Box sx={{ mt: 2 }}>
        <Card>
          <CardContent>
            <Box>
              <TextField
                defaultValue={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <Search />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search contract manager"
                variant="outlined"
                type="search"
                onChange={onChangeSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

ContractManagerListToolbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  search: PropTypes.string,
};

export default ContractManagerListToolbar;
