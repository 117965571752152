import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CountrySelect from '../CountrySelect';
import validator from 'validator';

const CustomerDetailsDialog = ({ open, setOpen, customer, loading, onSubmit }) => {
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (loading) {
      setEditing(false);
    }
  }, [loading]);

  const handleSave = (id, customerToSave) => {
    onSubmit(id, customerToSave);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetEditing = () => {
    if (!loading) {
      setEditing(true);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        CSN: customer?.CSN ?? '',
        shipTo: {
          name: customer?.shipTo?.name ?? '',
          altName: customer?.shipTo?.altName ?? '',
          addressLine1: customer?.shipTo?.addressLine1 ?? '',
          addressLine2: customer?.shipTo?.addressLine2 ?? '',
          addressLine3: customer?.shipTo?.addressLine3 ?? '',
          city: customer?.shipTo?.city ?? '',
          postalCode: customer?.shipTo?.postalCode ?? '',
          stateProvinceCode: customer?.shipTo?.stateProvinceCode ?? '',
          countryCode: customer?.shipTo?.countryCode ?? '',
          phoneNumber: customer?.shipTo?.phoneNumber ?? '',
        },
      }}
      validationSchema={Yup.object().shape({
        CSN: customer?.CSN
          ? Yup.string().max(10).required('CSN is required')
          : Yup.string().max(10),
        shipTo: Yup.object().shape({
          name: Yup.string().max(100).required('Name is required'),
          altName: Yup.string().max(35).required('Alt name is required'),
          addressLine1: Yup.string().max(35).required('Address is required'),
          addressLine2: Yup.string().max(35),
          addressLine3: Yup.string().max(35),
          city: Yup.string().max(35).required('City is required'),
          postalCode: Yup.string().max(35).required('Postal code is required'),
          stateProvinceCode: Yup.string().max(35),
          countryCode: Yup.string().max(2).required('Country code is required'),
          phoneNumber: Yup.string()
            .max(35)
            .test('phone-validation', 'Phone number is not valid', function (value) {
              if (!value) return false;
              return validator.isMobilePhone(value);
            }),
        }),
      })}
      onSubmit={(values, formikHelpers) => {
        handleSave(customer?._id, { ...values, account: customer?.account });
        formikHelpers.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={(event, reason) => {
              if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
              handleClose();
              resetForm();
            }}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Customer Details
              {customer?.name ? ` | ${customer?.name}` : ''}
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                error={Boolean(touched?.CSN && errors?.CSN)}
                fullWidth
                helperText={touched?.CSN && errors?.CSN}
                label="CSN*"
                margin="normal"
                name="CSN"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.CSN}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography marginTop={4} variant={'h5'} color={'textPrimary'}>
                Ship to
              </Typography>
              <TextField
                error={Boolean(touched?.shipTo?.name && errors?.shipTo?.name)}
                fullWidth
                helperText={touched?.shipTo?.name && errors?.shipTo?.name}
                label="Name*"
                margin="normal"
                name="shipTo.name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.name}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(touched?.shipTo?.altName && errors?.shipTo?.altName)}
                fullWidth
                helperText={touched?.shipTo?.altName && errors?.shipTo?.altName}
                label="Alt Name*"
                margin="normal"
                name="shipTo.altName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.altName}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(touched?.shipTo?.addressLine1 && errors?.shipTo?.addressLine1)}
                fullWidth
                helperText={touched?.shipTo?.addressLine1 && errors?.shipTo?.addressLine1}
                label="Address Line 1*"
                margin="normal"
                name="shipTo.addressLine1"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.addressLine1}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(touched?.shipTo?.addressLine2 && errors?.shipTo?.addressLine2)}
                fullWidth
                helperText={touched?.shipTo?.addressLine2 && errors?.shipTo?.addressLine2}
                label="Address Line 2"
                margin="normal"
                name="shipTo.addressLine2"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.addressLine2}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(touched?.shipTo?.addressLine3 && errors?.shipTo?.addressLine3)}
                fullWidth
                helperText={touched?.shipTo?.addressLine3 && errors?.shipTo?.addressLine3}
                label="Address Line 3"
                margin="normal"
                name="shipTo.addressLine3"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.addressLine3}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(touched?.shipTo?.city && errors?.shipTo?.city)}
                fullWidth
                helperText={touched?.shipTo?.city && errors?.shipTo?.city}
                label="City*"
                margin="normal"
                name="shipTo.city"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.city}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(touched?.shipTo?.postalCode && errors?.shipTo?.postalCode)}
                fullWidth
                helperText={touched?.shipTo?.postalCode && errors?.shipTo?.postalCode}
                label="Postal Code*"
                margin="normal"
                name="shipTo.postalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.postalCode}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={Boolean(
                  touched?.shipTo?.stateProvinceCode && errors?.shipTo?.stateProvinceCode,
                )}
                fullWidth
                helperText={touched?.shipTo?.stateProvinceCode && errors?.shipTo?.stateProvinceCode}
                label="State Province Code"
                margin="normal"
                name="shipTo.stateProvinceCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.stateProvinceCode}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {!editing ? (
                <TextField
                  error={Boolean(touched?.shipTo?.countryCode && errors?.shipTo?.countryCode)}
                  fullWidth
                  helperText={touched?.shipTo?.countryCode && errors?.shipTo?.countryCode}
                  label="Country Code*"
                  margin="normal"
                  name="shipTo.countryCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values?.shipTo?.countryCode}
                  variant="outlined"
                  InputProps={{
                    readOnly: !editing,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ) : (
                <CountrySelect
                  fullWidth
                  onChange={(e, value) => setFieldValue('shipTo.countryCode', value)}
                  value={values?.shipTo?.countryCode}
                  TextFieldProps={{
                    error: Boolean(touched?.shipTo?.countryCode && errors?.shipTo?.countryCode),
                    helperText: touched?.shipTo?.countryCode && errors?.shipTo?.countryCode,
                    label: 'Country Code*',
                    margin: 'normal',
                    name: 'shipTo.countryCode',
                    onBlur: handleBlur,
                    variant: 'outlined',
                    type: 'text',
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  disableClearable
                />
              )}
              <TextField
                error={Boolean(touched?.shipTo?.phoneNumber && errors?.shipTo?.phoneNumber)}
                fullWidth
                helperText={touched?.shipTo?.phoneNumber && errors?.shipTo?.phoneNumber}
                label="Phone Number"
                margin="normal"
                name="shipTo.phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.shipTo?.phoneNumber}
                variant="outlined"
                InputProps={{
                  readOnly: !editing,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </DialogContent>
            <DialogActions>
              {!editing ? (
                <Button onClick={handleSetEditing}>Edit</Button>
              ) : (
                <>
                  <Button
                    disabled={loading}
                    variant={'contained'}
                    onClick={handleSubmit}
                    endIcon={loading ? <CircularProgress color={'inherit'} size={16} /> : undefined}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setEditing(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
              <Button
                onClick={() => {
                  setEditing(false);
                  resetForm();
                  handleClose();
                }}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};
CustomerDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  customer: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomerDetailsDialog;
