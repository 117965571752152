import theme from '../theme';

const orderStatus = {
  PENDING_SUBMIT: 'pending',
  SUBMITTED: 'submitted',
  PROCESSING: 'processing',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

export const orderStatusColors = {
  PENDING_SUBMIT: theme.palette.background.paper,
  SUBMITTED: theme.palette.info.main,
  PROCESSING: theme.palette.warning.main,
  SUCCESSFUL: theme.palette.success.main,
  FAILED: theme.palette.error.main,
};

export default orderStatus;
