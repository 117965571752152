import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
  query GetMindwareservicesCustomers($skip: Number!, $limit: Number!, $searchString: String) {
    getMindwareservicesCustomers(skip: $skip, limit: $limit, searchString: $searchString) {
      documentsCount
      documents {
        _id
        createdAt
        CSN
        account {
          name
          altName
          addressLine1
          addressLine2
          addressLine3
          city
          postalCode
          stateProvinceCode
          countryCode
          phoneNumber
        }
        shipTo {
          name
          altName
          addressLine1
          addressLine2
          addressLine3
          city
          postalCode
          stateProvinceCode
          countryCode
          phoneNumber
        }
      }
    }
  }
`;
