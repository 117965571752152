import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import ProductDetailsDialog from '../product/ProductDetailsDialog';
import Decimal from 'decimal.js';
import DialogTitle from '@material-ui/core/DialogTitle';

const ContractAddSeatsDialog = ({
  open,
  setOpen,
  contract,
  finalizeOrder,
  finalizeLoading,
  ...rest
}) => {
  const [addSeatsProducts, setAddSeatsProducts] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [openBasketItemDetails, setOpenBasketItemDetails] = useState(false);
  const [poText, setPoText] = useState();

  useEffect(() => {
    setAddSeatsProducts({});
  }, [contract, open]);

  const handleQuantityChange = (event, serialNumber) => {
    const productsToAdd = { ...addSeatsProducts };
    if (event.target.value > 0) {
      productsToAdd[serialNumber] = Number(event.target.value);
    } else {
      delete productsToAdd[serialNumber];
    }
    setAddSeatsProducts(productsToAdd);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowBasketItemDetails = (basketItem) => {
    if (basketItem) {
      setSelectedItem(basketItem);
      setOpenBasketItemDetails(true);
    }
  };

  const handleFinalize = () => {
    const productValues = Object.entries(addSeatsProducts).map(([key, value]) => {
      return { serialNumber: key, quantity: value };
    });
    const finalizeValues = {
      contract: contract?._id,
      products: productValues.length > 0 ? productValues : undefined,
      poText: poText,
    };
    finalizeOrder(finalizeValues);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        {...rest}
      >
        <DialogTitle id="scroll-dialog-title">
          Add Seats
          {contract?.contractNumber ? ` | ${contract?.contractNumber}` : ''}
        </DialogTitle>
        <DialogContent>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contract?.products
                    ? contract.products
                        .filter((prodItem) => !prodItem.product?.isPack)
                        .map((prodItem) => (
                          <TableRow
                            hover
                            key={prodItem.product?._id}
                            // onClick={(event) => handleShowBasketItemDetails(prodItem)}
                          >
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {prodItem.product?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <TextField
                                onClick={(e) => e.stopPropagation()}
                                size="small"
                                fullWidth
                                margin="dense"
                                name="email"
                                onChange={(event) =>
                                  handleQuantityChange(event, prodItem.serial_number)
                                }
                                value={addSeatsProducts[prodItem.serial_number] ?? 0}
                                inputProps={{ min: 0 }}
                                type="number"
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              {new Decimal(prodItem.product?.costPrice ?? 0)
                                .mul(addSeatsProducts[prodItem.serial_number] ?? 0)
                                .toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))
                    : null}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Order Total</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {addSeatsProducts
                          ? Object.values(addSeatsProducts).reduce(
                              (totalQuantity, quantity) =>
                                new Decimal(quantity).add(totalQuantity).toFixed(0),
                              0,
                            )
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {contract?.products
                          ? contract.products?.reduce(
                              (totalCostPrice, currentItem) =>
                                new Decimal(currentItem.product?.costPrice ?? 0)
                                  .mul(addSeatsProducts[currentItem.serial_number] ?? 0)
                                  .add(totalCostPrice)
                                  .toFixed(2),
                              0,
                            )
                          : null}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TextField
            sx={{ mt: 2 }}
            size="small"
            inputProps={{ maxLength: 35 }}
            fullWidth
            label="PO number"
            margin="normal"
            name="poText"
            onChange={(event) => setPoText(event.target.value.trim().replace(/[^0-9a-z]/gi, ''))}
            type="text"
            value={poText}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            color={'primary'}
            onClick={handleFinalize}
            disabled={finalizeLoading || Object.entries(addSeatsProducts).length === 0}
            endIcon={finalizeLoading ? <CircularProgress color={'inherit'} size={16} /> : undefined}
          >
            Finalize
          </Button>
        </DialogActions>
      </Dialog>
      <ProductDetailsDialog
        setOpen={setOpenBasketItemDetails}
        open={openBasketItemDetails}
        product={selectedItem?.product}
      />
    </>
  );
};

ContractAddSeatsDialog.propTypes = {
  contract: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  finalizeOrder: PropTypes.func,
  finalizeLoading: PropTypes.bool,
};

export default ContractAddSeatsDialog;
