import { gql } from '@apollo/client';

export const ADD_CUSTOMER = gql`
  mutation PostMindwareservicesCustomers($params: postMindwareservicesCustomersRequest) {
    postMindwareservicesCustomers(params: $params) {
      _id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation PostMindwareservicesCustomers(
    $id: String
    $params: postMindwareservicesCustomersRequest
  ) {
    postMindwareservicesCustomers(id: $id, params: $params) {
      _id
    }
  }
`;
