import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import SettingsPassword from 'src/components/settings/SettingsPassword';
import SettingsToolbar from '../../components/settings/SettingsToolbar';
import SettingsUserListResults from '../../components/settings/SettingsUserListResults';
import SettingsUserAddDialog from '../../components/settings/SettingsUserAddDialog';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CHANGE_PASSWORD, REGISTER } from '../../graphql/mutations';
import SettingsMargin from '../../components/settings/SettingsMargin';
import { ADD_USER } from '../../graphql/mutations/UserMutations';
import { GET_USERS } from '../../graphql/queries/UserQueries';
import { PLATFORM_SETTINGS } from '../../graphql/queries/PlatformQueries';
import { CHANGE_PLATFORM_SETTINGS } from '../../graphql/mutations/PlatformMutations';
import { SUBMIT_PRICE_LIST, SYNC } from '../../graphql/mutations/ServicesMutations';

const INIT_SKIP = 0;
const INIT_LIMIT = 10;

const ResellerSettings = () => {
  const [skip, setSkip] = useState(INIT_SKIP);
  const [limit, setLimit] = useState(INIT_LIMIT);

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const {
    data: getUsersData,
    fetchMore: fetchMoreUsersData,
    refetch: refetchUsersData,
    called: calledUsersData,
    error: getUsersError,
  } = useQuery(GET_USERS, {
    variables: { skip: INIT_SKIP, limit: INIT_LIMIT, sort: ['-createdAt'] },
  });
  const { data: platformSettingsData, error: getPlatformSettingsError } = useQuery(
    PLATFORM_SETTINGS,
    {
      variables: { skip: 0, limit: 1 },
    },
  );
  const [
    changePlatformSettings,
    {
      data: changePlatformSettingsData,
      loading: changePlatformSettingsLoading,
      error: changePlatformSettingsError,
    },
  ] = useMutation(CHANGE_PLATFORM_SETTINGS);
  const [
    changePassword,
    { data: changePasswordData, loading: changePasswordLoading, error: changePasswordError },
  ] = useMutation(CHANGE_PASSWORD);
  const [registerUser, { error: registerUserError }] = useMutation(REGISTER);
  const [addUser, { data: addUserData, error: addUserError }] = useMutation(ADD_USER);
  const [sync, { loading: loadingSync, data: dataSync, error: syncError }] = useMutation(SYNC);
  const [
    submitPriceList,
    { loading: loadingSubmitPriceList, data: dataSubmitPriceList, error: submitPricelistError },
  ] = useMutation(SUBMIT_PRICE_LIST);

  useEffect(() => {
    if (calledUsersData) {
      const docCount = getUsersData?.getCmsUserData?.documentsCount ?? 0;
      const currentCount = getUsersData?.getCmsUserData?.documents?.length ?? 0;
      const skipLimit = skip + limit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreUsersData({
          variables: { skip: skip, limit: limit },
        });
      }
    }
  }, [skip, limit]);

  useEffect(() => {
    if (getUsersError) {
      setSnackbarMessage(getUsersError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [getUsersError]);

  useEffect(() => {
    if (getPlatformSettingsError) {
      setSnackbarMessage(getPlatformSettingsError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [getPlatformSettingsError]);

  useEffect(() => {
    if (registerUserError) {
      setSnackbarMessage(registerUserError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [registerUserError]);

  useEffect(() => {
    if (changePasswordError) {
      setSnackbarMessage(changePasswordError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (changePasswordData) {
      setSnackbarMessage('Password has been changed');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [changePasswordData, changePasswordError]);

  useEffect(() => {
    if (addUserError) {
      setSnackbarMessage(addUserError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (addUserData) {
      setSnackbarMessage('User has been added');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      refetchUsersData();
    }
  }, [addUserData, addUserError]);

  useEffect(() => {
    if (changePlatformSettingsError) {
      setSnackbarMessage(changePlatformSettingsError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (changePlatformSettingsData) {
      setSnackbarMessage('Margin has been changed');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [changePlatformSettingsData, changePlatformSettingsError]);

  useEffect(() => {
    if (syncError) {
      setSnackbarMessage(syncError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (dataSync) {
      setSnackbarMessage('Sync has started');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [dataSync, syncError]);

  useEffect(() => {
    if (submitPricelistError) {
      setSnackbarMessage(submitPricelistError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (dataSubmitPriceList) {
      setSnackbarMessage('Price list has been submitted');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [dataSubmitPriceList, submitPricelistError]);

  const handleSetLimit = (limit) => {
    setLimit(limit);
    setSkip(INIT_SKIP);
  };

  const handleImportProducts = (value) => {
    submitPriceList({
      variables: {
        params: {
          priceList: value,
        },
      },
    });
  };

  const handleAccountSync = () => {
    sync({
      variables: {
        syncType: 'accounts',
      },
    });
  };

  const handleSubscriptionSync = () => {
    sync({
      variables: {
        syncType: 'subscriptions',
      },
    });
  };

  const handleAddUser = (values) => {
    registerUser({
      variables: {
        params: {
          email: values?.email,
          password: values?.password,
        },
      },
    }).then(({ data }) =>
      addUser({
        variables: {
          params: {
            user: data?.postAuthenticationLocalNew?.userId,
            email: values?.email,
            fullname: values?.fullname,
            userType: 'DISTRIBUTOR',
          },
        },
      }),
    );
  };

  const handleDeleteUser = (values) => {
    alert(`DELETE USER: ${JSON.stringify(values, null, 2)}`);
  };

  const handleChangePassword = (values) => {
    changePassword({
      variables: {
        params: {
          oldPassword: values?.oldPassword,
          newPassword: values?.newPassword,
        },
      },
    });
  };

  const handleChangeMargin = (values) => {
    // alert(`CHANGE MARGIN: ${JSON.stringify(values, null, 2)}`);
    changePlatformSettings({
      variables: {
        params: values,
        id: platformSettingsData?.getCmsPlatformSettings?.documents[0]?._id,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          {/*<SettingsToolbar*/}
          {/*  onImportProducts={handleImportProducts}*/}
          {/*  importLoading={loadingSubmitPriceList}*/}
          {/*  onAccountSync={handleAccountSync}*/}
          {/*  onSubscriptionSync={handleSubscriptionSync}*/}
          {/*  syncLoading={loadingSync}*/}
          {/*/>*/}
          {/*<SettingsMargin*/}
          {/*  currentMargin={platformSettingsData?.getCmsPlatformSettings?.documents[0]?.margin}*/}
          {/*  onChangeMargin={handleChangeMargin}*/}
          {/*  loading={changePlatformSettingsLoading}*/}
          {/*  sx={{ mt: 2 }}*/}
          {/*/>*/}
          {/*<SettingsUserListResults*/}
          {/*  sx={{ mt: 2 }}*/}
          {/*  users={getUsersData?.getCmsUserData?.documents ?? []}*/}
          {/*  count={getUsersData?.getCmsUserData?.documentsCount ?? 0}*/}
          {/*  onDelete={handleDeleteUser}*/}
          {/*  onAdd={() => setAddUserOpen(true)}*/}
          {/*  skip={skip}*/}
          {/*  setSkip={setSkip}*/}
          {/*  limit={limit}*/}
          {/*  setLimit={handleSetLimit}*/}
          {/*/>*/}
          <SettingsPassword
            onChangePassword={handleChangePassword}
            loading={changePasswordLoading}
            sx={{ mt: 2 }}
          />
        </Container>
      </Box>
      <SettingsUserAddDialog setOpen={setAddUserOpen} onSubmit={handleAddUser} open={addUserOpen} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ResellerSettings;
