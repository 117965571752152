import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetCmsUserData($skip: Number, $limit: Number, $sort: [String]) {
    getCmsUserData(skip: $skip, limit: $limit, sort: $sort) {
      documentsCount
      documents {
        _id
        createdAt
        CSN
        userType
        fullname
        email
        user {
          _id
        }
      }
    }
  }
`;

export const GET_DISTI = gql`
  query GetCmsFunctionGetDisti($skip: Number!, $limit: Number!, $sort: [String]) {
    getCmsFunctionGetDisti(skip: $skip, limit: $limit, sort: $sort) {
      documentsCount
      documents {
        _id
        createdAt
        CSN
        userType
        fullname
        email
        user {
          _id
        }
      }
    }
  }
`;

export const GET_RESELLERS = gql`
  query GetCmsFunctionSearchResellers(
    $params: getCmsFunctionSearchResellersRequest
    $skip: Number!
    $limit: Number!
    $sort: [String]
  ) {
    getCmsFunctionSearchResellers(params: $params, skip: $skip, limit: $limit, sort: $sort) {
      documentsCount
      documents {
        _id
        createdAt
        CSN
        userType
        fullname
        email
        user {
          _id
        }
      }
    }
  }
`;

export const GET_MY_USER = gql`
  query getCmsFunctionGetMyUser {
    getCmsFunctionGetMyUser {
      result {
        _id
        createdAt
        CSN
        userType
        fullname
        email
        user {
          _id
        }
      }
    }
  }
`;
