import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import DistributorDashboard from 'src/pages/distributor/DistributorDashboard';
import Login from 'src/pages/auth/Login';
import NotFound from 'src/pages/NotFound';
import DistributorSettings from 'src/pages/distributor/DistributorSettings';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import DistributorResellerList from './pages/distributor/DistributorResellerList';
import DistributorOrderList from './pages/distributor/DistributorOrderList';
import ResellerDashboard from './pages/reseller/ResellerDashboard';
import ResellerSettings from './pages/reseller/ResellerSettings';
import ResellerCustomerList from './pages/reseller/ResellerCustomerList';
import ResellerProductList from './pages/reseller/ResellerProductList';
import ResellerOrderList from './pages/reseller/ResellerOrderList';
import ResellerBasket from './pages/reseller/ResellerBasket';
import ResellerContractList from './pages/reseller/ResellerContractList';

const routes = (userType) => {
  const userTypePath = userType?.toLowerCase();
  return [
    {
      path: 'distributor',
      element: userTypePath === 'distributor' ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'dashboard', element: <DistributorDashboard /> },
        { path: 'orders', element: <DistributorOrderList /> },
        { path: 'resellers', element: <DistributorResellerList /> },
        { path: 'settings', element: <DistributorSettings /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'reseller',
      element: userTypePath === 'reseller' ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'dashboard', element: <ResellerDashboard /> },
        { path: 'customers', element: <ResellerCustomerList /> },
        { path: 'products', element: <ResellerProductList /> },
        { path: 'orders', element: <ResellerOrderList /> },
        { path: 'contracts', element: <ResellerContractList /> },
        { path: 'settings', element: <ResellerSettings /> },
        { path: 'basket', element: <ResellerBasket /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: !userTypePath ? <MainLayout /> : <Navigate to={`/${userTypePath}/dashboard`} />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ];
};

export default routes;
