import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import ResellerListToolbar from '../../components/reseller/ResellerListToolbar';
import ResellerListResults from '../../components/reseller/ResellerListResults';
import ResellerAddDialog from '../../components/reseller/ResellerAddDialog';
import ResellerEditDialog from '../../components/reseller/ResellerEditDialog';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { REGISTER } from '../../graphql/mutations';
import { ADD_USER, DELETE_USER, EDIT_USER } from '../../graphql/mutations/UserMutations';
import { GET_RESELLERS } from '../../graphql/queries/UserQueries';
import { IMPERSONATE_RESELLER } from '../../graphql/queries';
import ResellerCreateAccountDialog from '../../components/reseller/ResellerCreateAccountDialog';

const INIT_SKIP = 0;
const INIT_LIMIT = 10;

const DistributorResellerList = () => {
  const [skip, setSkip] = useState(INIT_SKIP);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [searchText, setSearchText] = useState(undefined);

  const [editedReseller, setEditedReseller] = useState(null);
  const [addResellerOpen, setAddResellerOpen] = useState(false);
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  const [editResellerOpen, setEditResellerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const {
    data: getResellerData,
    fetchMore: fetchMoreResellerData,
    refetch: refetchResellerData,
    called: calledResellerData,
    error: errorResellerData,
  } = useQuery(GET_RESELLERS, {
    variables: {
      params: { searchTest: searchText },
      skip: INIT_SKIP,
      limit: INIT_LIMIT,
      sort: ['-createdAt'],
    },
  });
  const [registerUser, { error: errorRegisterUser }] = useMutation(REGISTER);
  const [addUser, { data: addUserData, error: errorAddUser }] = useMutation(ADD_USER, {
    refetchQueries: [
      {
        query: GET_RESELLERS,
        variables: {
          params: { searchTest: searchText },
          skip: skip,
          limit: limit,
          sort: ['-createdAt'],
        },
      },
    ],
  });
  const [editUser, { data: editUserData, error: errorEditUser }] = useMutation(EDIT_USER, {
    refetchQueries: [
      {
        query: GET_RESELLERS,
        variables: {
          params: { searchTest: searchText },
          skip: skip,
          limit: limit,
          sort: ['-createdAt'],
        },
      },
    ],
  });
  const [deleteUser, { data: deleteUserData, error: errorDeleteUser }] = useMutation(DELETE_USER, {
    refetchQueries: [
      {
        query: GET_RESELLERS,
        variables: {
          params: { searchTest: searchText },
          skip: skip,
          limit: limit,
          sort: ['-createdAt'],
        },
      },
    ],
  });
  const [
    impersonateReseller,
    {
      data: dataImpersonateReseller,
      error: errorImpersonateReseller,
      refetch: refetchImpersonateReseller,
      called: calledImpersonateReseller,
    },
  ] = useLazyQuery(IMPERSONATE_RESELLER);

  useEffect(() => {
    if (calledResellerData) {
      const docCount = getResellerData?.getCmsFunctionSearchResellers?.documentsCount ?? 0;
      const currentCount = getResellerData?.getCmsFunctionSearchResellers?.documents?.length ?? 0;
      const skipLimit = skip + limit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreResellerData({
          variables: { params: { searchTest: searchText }, skip: skip, limit: limit },
        });
      }
    }
  }, [skip]);

  useEffect(() => {
    if (calledResellerData) {
      refetchResellerData({ params: { searchTest: searchText }, skip: skip, limit: limit });
    }
  }, [searchText, limit]);

  useEffect(() => {
    if (errorResellerData) {
      setSnackbarMessage(errorResellerData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorResellerData]);

  useEffect(() => {
    if (errorRegisterUser) {
      setSnackbarMessage(errorRegisterUser.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorRegisterUser]);

  useEffect(() => {
    if (errorAddUser) {
      setSnackbarMessage(errorAddUser.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (addUserData) {
      setSnackbarMessage('Reseller has been added');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [addUserData, errorAddUser]);

  useEffect(() => {
    if (errorEditUser) {
      setSnackbarMessage(errorEditUser.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (editUserData) {
      setSnackbarMessage('Reseller has been edited');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [editUserData, errorEditUser]);

  useEffect(() => {
    if (errorDeleteUser) {
      setSnackbarMessage(errorDeleteUser.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (deleteUserData) {
      setSnackbarMessage('Reseller has been deleted');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [deleteUserData, errorDeleteUser]);

  useEffect(() => {
    if (errorImpersonateReseller) {
      setSnackbarMessage(errorImpersonateReseller.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (dataImpersonateReseller) {
      const url = `${window.location.origin}/?session-access-token=${dataImpersonateReseller?.getMindwareservicesImpersonateReseller?.result}`;

      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    }
  }, [dataImpersonateReseller, errorImpersonateReseller]);

  useEffect(() => {
    if (!editResellerOpen) {
      setEditedReseller(null);
    }
  }, [editResellerOpen]);

  const handleAddReseller = (values) => {
    registerUser({
      variables: {
        params: {
          email: values?.email,
          password: values?.password,
        },
      },
    }).then(({ data }) =>
      addUser({
        variables: {
          params: {
            user: data?.postAuthenticationLocalNew?.userId,
            CSN: values?.CSN,
            email: values?.email,
            fullname: values?.fullname,
            userType: 'RESELLER',
          },
        },
      }),
    );
  };

  const handleCreateResellerAccount = (values) => {
    registerUser({
      variables: {
        params: {
          email: values?.email,
          password: values?.password,
        },
      },
    }).then(({ data }) =>
      editUser({
        variables: {
          params: {
            user: data?.postAuthenticationLocalNew?.userId,
            CSN: values?.CSN,
            email: values?.email,
            fullname: values?.fullname,
            userType: 'RESELLER',
            // createdAt: values?.createdAt,
            // updatedAt: values?.updatedAt,
          },
          id: values?._id,
        },
      }),
    );
  };

  const handleEditReseller = (values) => {
    editUser({
      variables: {
        params: {
          user: values?.user._id,
          CSN: values?.CSN,
          email: values?.email,
          fullname: values?.fullname,
          userType: values?.userType,
          // createdAt: values?.createdAt,
          // updatedAt: values?.updatedAt,
        },
        id: values?._id,
      },
    });
  };

  const handleDeleteReseller = (values) => {
    deleteUser({
      variables: {
        id: values?._id,
      },
    });
  };

  const handleOpenEdit = (values) => {
    setEditedReseller(values);
    setEditResellerOpen(true);
  };

  const handleOpenCreateAccount = (values) => {
    setEditedReseller(values);
    setCreateAccountOpen(true);
  };

  const handleImpersonate = (values) => {
    if (!calledImpersonateReseller) {
      impersonateReseller({
        variables: {
          reseller: values?._id,
        },
      });
    } else {
      refetchImpersonateReseller({
        reseller: values?._id,
      });
    }
  };

  const handleSetLimit = (limit) => {
    setLimit(limit);
    setSkip(INIT_SKIP);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    setSkip(INIT_SKIP);
  };

  return (
    <>
      <Helmet>
        <title>Resellers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <ResellerListToolbar onAdd={() => setAddResellerOpen(true)} onSearch={handleSearch} />
          <ResellerListResults
            sx={{ mt: 2 }}
            resellers={getResellerData?.getCmsFunctionSearchResellers?.documents ?? []}
            count={getResellerData?.getCmsFunctionSearchResellers?.documentsCount ?? 0}
            onDelete={handleDeleteReseller}
            onEdit={handleOpenEdit}
            onImpersonate={handleImpersonate}
            skip={skip}
            setSkip={setSkip}
            limit={limit}
            setLimit={handleSetLimit}
            onCreateAccount={handleOpenCreateAccount}
          />
        </Container>
      </Box>
      <ResellerAddDialog
        open={addResellerOpen}
        setOpen={setAddResellerOpen}
        onSubmit={handleAddReseller}
      />
      <ResellerCreateAccountDialog
        open={createAccountOpen}
        setOpen={setCreateAccountOpen}
        onSubmit={handleCreateResellerAccount}
        currentValues={editedReseller}
      />
      <ResellerEditDialog
        open={editResellerOpen}
        setOpen={setEditResellerOpen}
        onSubmit={handleEditReseller}
        currentValues={editedReseller}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default DistributorResellerList;
