import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import OrderListResults from '../../components/order/OrderListResults';
import OrderListToolbar from '../../components/order/OrderListToolbar';
import { useQuery } from '@apollo/client';
import { GET_ORDERS } from '../../graphql/queries/OrderQueries';

const INIT_SKIP = 0;
const INIT_LIMIT = 10;

const ResellerOrderList = () => {
  const [skip, setSkip] = useState(INIT_SKIP);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [searchText, setSearchText] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [paid, setPaid] = useState(undefined);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const {
    data: ordersData,
    fetchMore: ordersFetchMore,
    refetch: ordersRefetch,
    called: ordersCalled,
    error: ordersError,
  } = useQuery(GET_ORDERS, {
    variables: {
      searchString: searchText,
      skip: INIT_SKIP,
      limit: INIT_LIMIT,
      sort: ['-createdAt'],
    },
  });

  useEffect(() => {
    if (ordersCalled) {
      const docCount = ordersData?.getMindwareservicesOrders?.documentsCount ?? 0;
      const currentCount = ordersData?.getMindwareservicesOrders?.documents?.length ?? 0;
      const skipLimit = skip + limit;
      if (currentCount < docCount && currentCount < skipLimit) {
        ordersFetchMore({
          variables: {
            searchString: searchText,
            skip: skip,
            limit: limit,
            status: status,
            paid: paid,
            sort: ['-createdAt'],
          },
        });
      }
    }
  }, [skip]);

  useEffect(() => {
    if (ordersCalled) {
      ordersRefetch({
        searchString: searchText,
        skip: skip,
        limit: limit,
        status: status,
        paid: paid,
        sort: ['-createdAt'],
      });
    }
  }, [limit, searchText, status, paid]);

  useEffect(() => {
    if (ordersError) {
      setSnackbarMessage(ordersError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [ordersError]);

  const handleExportOrders = () => {
    alert(`EXPORT ORDERS: ${JSON.stringify(ordersData, null, 2)}`);
  };

  const handleSetLimit = (limit) => {
    setLimit(limit);
    setSkip(INIT_SKIP);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    setSkip(INIT_SKIP);
  };

  const handleSetStatus = (status) => {
    setStatus(status);
    setSkip(INIT_SKIP);
  };

  const handleSetPaid = (paid) => {
    setPaid(paid);
    setSkip(INIT_SKIP);
  };

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <OrderListToolbar
            onExport={handleExportOrders}
            onSearch={handleSearch}
            search={searchText}
            orderStatusFilter={status}
            setOrderStatusFilter={handleSetStatus}
            paidFilter={paid}
            setPaidFilter={handleSetPaid}
          />
          <OrderListResults
            sx={{ mt: 2 }}
            orders={ordersData?.getMindwareservicesOrders?.documents ?? []}
            count={ordersData?.getMindwareservicesOrders?.documentsCount ?? 0}
            skip={skip}
            setSkip={setSkip}
            limit={limit}
            setLimit={handleSetLimit}
          />
        </Container>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResellerOrderList;
