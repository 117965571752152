import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import ProductDetailsDialog from '../product/ProductDetailsDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import isTradeIn from '../../utils/isTradeIn';

const ContractRenewalDialog = ({
  open,
  setOpen,
  opportunity,
  contractId,
  finalizeOrder,
  finalizeLoading,
  ...rest
}) => {
  const [renewProducts, setRenewProducts] = useState([]);
  const [tradeInProducts, setTradeInProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openBasketItemDetails, setOpenBasketItemDetails] = useState(false);
  const [poText, setPoText] = useState();

  useEffect(() => {
    setRenewProducts([]);
  }, [opportunity, open]);

  const handleCheckedChange = (event, product) => {
    if (event.target.checked) {
      setRenewProducts([...renewProducts, product]);
    } else {
      const renewProd = renewProducts.filter(
        (item) => item.serial_number !== product.serial_number,
      );
      setRenewProducts(renewProd);
    }
  };

  const handleSkuChange = (event, serialNumber, quantity) => {
    const productsToAdd = { ...tradeInProducts };
    productsToAdd[serialNumber] = {
      renewal_sku: event.target.value.trim(),
      quantity: quantity * 2,
    };
    setTradeInProducts(productsToAdd);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowBasketItemDetails = (basketItem) => {
    if (basketItem) {
      setSelectedItem(basketItem);
      setOpenBasketItemDetails(true);
    }
  };

  const handleFinalize = () => {
    let tradeIn = false;
    let productValues;
    if (tradeInProducts.length !== 0) {
      tradeIn = true;
      productValues = Object.keys(tradeInProducts).map((key) => {
        return {
          serialNumber: key,
          quantity: tradeInProducts[key].quantity,
          renewalSku: tradeInProducts[key].renewal_sku,
        };
      });
    } else {
      productValues = renewProducts.map((product) => {
        return {
          serialNumber: product.serial_number,
          quantity: product.quantity,
          renewalSku: product.renewal_sku,
        };
      });
    }
    const finalizeValues = {
      contract: contractId,
      tradeIn,
      opportunityNumber: opportunity?.opportunity_number,
      products: productValues.length > 0 ? productValues : undefined,
      poText: poText,
    };
    finalizeOrder(finalizeValues);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        {...rest}
      >
        <DialogTitle id="scroll-dialog-title">
          Renew Opportunity
          {opportunity?.opportunity_number ? ` | ${opportunity?.opportunity_number}` : ''}
        </DialogTitle>
        <DialogContent>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding={'checkbox'} />
                    <TableCell>Serial Number</TableCell>
                    <TableCell>SKU Description</TableCell>
                    <TableCell>Renewal SKU</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opportunity?.products
                    ? opportunity.products.map((prodItem) =>
                        !isTradeIn(prodItem) ? (
                          <TableRow
                            hover
                            key={prodItem?.serial_number}
                            // onClick={(event) => handleShowBasketItemDetails(prodItem)}
                          >
                            <TableCell padding={'checkbox'}>
                              <Checkbox
                                checked={
                                  renewProducts.findIndex(
                                    (item) => item?.serial_number === prodItem?.serial_number,
                                  ) !== -1
                                }
                                onChange={(event) => handleCheckedChange(event, prodItem)}
                                onClick={(event) => event.stopPropagation()}
                                // TODO :: handle trade in products with isTradeIn(prodItem)
                              />
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {prodItem?.serial_number}
                              </Typography>
                            </TableCell>
                            <TableCell>{prodItem?.sku_description}</TableCell>
                            <TableCell>{prodItem?.renewal_sku}</TableCell>
                            <TableCell>{prodItem?.quantity}</TableCell>
                          </TableRow>
                        ) : (
                          <TableRow hover key={prodItem?.serial_number}>
                            <TableCell padding={'checkbox'} />
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {prodItem?.serial_number}
                              </Typography>
                            </TableCell>
                            <TableCell>{prodItem?.sku_description}</TableCell>
                            <TableCell>
                              {' '}
                              <TextField
                                onClick={(e) => e.stopPropagation()}
                                size="small"
                                fullWidth
                                margin="dense"
                                name="email"
                                onChange={(event) =>
                                  handleSkuChange(
                                    event,
                                    prodItem?.serial_number,
                                    prodItem?.quantity,
                                  )
                                }
                                value={tradeInProducts[prodItem.serial_number]?.renewal_sku ?? ''}
                                type="text"
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>{prodItem?.quantity}</TableCell>
                          </TableRow>
                        ),
                      )
                    : null}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TextField
            sx={{ mt: 2 }}
            size="small"
            inputProps={{ maxLength: 35 }}
            fullWidth
            label="PO number"
            margin="normal"
            name="poText"
            onChange={(event) => setPoText(event.target.value.trim().replace(/[^0-9a-z]/gi, ''))}
            type="text"
            value={poText}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            color={'primary'}
            onClick={handleFinalize}
            disabled={
              finalizeLoading ||
              (renewProducts.length === 0 && Object.entries(tradeInProducts).length === 0)
            }
            endIcon={finalizeLoading ? <CircularProgress color={'inherit'} size={16} /> : undefined}
          >
            Finalize
          </Button>
        </DialogActions>
      </Dialog>
      <ProductDetailsDialog
        setOpen={setOpenBasketItemDetails}
        open={openBasketItemDetails}
        product={selectedItem?.product}
      />
    </>
  );
};

ContractRenewalDialog.propTypes = {
  opportunity: PropTypes.object,
  contractId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  finalizeOrder: PropTypes.func,
  finalizeLoading: PropTypes.bool,
};

export default ContractRenewalDialog;
