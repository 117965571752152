import React from 'react';
import { Box, Card, CardContent, Stepper, Step, StepLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const steps = ['Order information', 'Customer information'];

const BasketToolbar = ({ activeStep, ...rest }) => {
  return (
    <Box {...rest}>
      <Card>
        <CardContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    </Box>
  );
};

BasketToolbar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  // onAdd: PropTypes.func.isRequired,
  // onSearch: PropTypes.func.isRequired,
};

export default BasketToolbar;
