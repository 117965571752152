import { gql } from '@apollo/client';

export const ADD_TO_BASKET = gql`
  mutation PostMindwareservicesBasket($product: String!, $quantity: Number!) {
    postMindwareservicesBasket(product: $product, quantity: $quantity) {
      _id
    }
  }
`;

export const DELETE_FROM_BASKET = gql`
  mutation DeleteMindwareservicesBasket($product: String!) {
    deleteMindwareservicesBasket(product: $product) {
      _id
    }
  }
`;

export const EMPTY_BASKET = gql`
  mutation DeleteMindwareservicesEmptyBasket {
    deleteMindwareservicesEmptyBasket {
      _id
    }
  }
`;
