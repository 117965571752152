import { gql } from '@apollo/client';

export const PLATFORM_SETTINGS = gql`
  query GetCmsPlatformSettings($skip: Number, $limit: Number, $sort: [String]) {
    getCmsPlatformSettings(skip: $skip, limit: $limit, sort: $sort) {
      documents {
        _id
        margin
      }
    }
  }
`;
