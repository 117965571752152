import React from 'react';
import { Box, Card, CardContent, Button, CircularProgress, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { DatePicker } from '@material-ui/lab';
import { enGB } from 'date-fns/locale';
import { addDays, isBefore, parseISO } from 'date-fns';

const BasketButtons = ({
  activeStep,
  setActiveStep,
  clearBasket,
  checkoutDisabled,
  finalizeOrder,
  finalizeDisabled,
  finalizeLoading,
  submitOrder,
  startDate,
  setStartDate,
  contractExpiration,
  ...rest
}) => {
  const renderNextButton = () => {
    switch (activeStep) {
      case 0:
        return (
          <Button
            variant="contained"
            color={'primary'}
            disabled={checkoutDisabled}
            onClick={() => setActiveStep(activeStep + 1)}
          >
            Checkout
          </Button>
        );
      case 1:
        return (
          <Button
            variant="contained"
            color={'primary'}
            onClick={finalizeOrder}
            disabled={finalizeDisabled}
            endIcon={finalizeLoading ? <CircularProgress color={'inherit'} size={16} /> : undefined}
          >
            Finalize
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Card {...rest}>
      <CardContent>
        <Box display={'flex'} justifyContent={'space-between'}>
          {activeStep === 0 ? (
            <Button disabled={checkoutDisabled} color={'error'} onClick={clearBasket}>
              Clear Basket
            </Button>
          ) : (
            <Box />
          )}
          <Box display={'flex'}>
            {activeStep > 0 ? (
              <Button
                color={'primary'}
                sx={{ mr: 2 }}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
            ) : null}
            {activeStep === 1 ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                <DatePicker
                  minDate={new Date()}
                  maxDate={
                    contractExpiration
                      ? isBefore(parseISO(contractExpiration), addDays(new Date(), 30))
                        ? parseISO(contractExpiration)
                        : addDays(new Date(), 30)
                      : addDays(new Date(), 30)
                  }
                  label="Start date"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={({ InputProps, ...restParams }) => (
                    <TextField
                      InputProps={{ ...InputProps, style: { height: 36 } }}
                      {...restParams}
                      sx={{ mr: 2, width: 150 }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            ) : null}
            {renderNextButton()}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

BasketButtons.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  clearBasket: PropTypes.func.isRequired,
  checkoutDisabled: PropTypes.bool,
  finalizeOrder: PropTypes.func.isRequired,
  finalizeDisabled: PropTypes.bool,
  finalizeLoading: PropTypes.bool,
  submitOrder: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  contractExpiration: PropTypes.string,
};

export default BasketButtons;
