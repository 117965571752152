const accessTokenKey = 'mindware-portal-access-token';
const refreshTokenKey = 'mindware-portal-refresh-token';

export const accessTokenStorage = {
  write: (token) => localStorage.setItem(accessTokenKey, token),
  read: () => localStorage.getItem(accessTokenKey),
  delete: () => localStorage.removeItem(accessTokenKey),
};

export const refreshTokenStorage = {
  write: (token) => localStorage.setItem(refreshTokenKey, token),
  read: () => localStorage.getItem(refreshTokenKey),
  delete: () => localStorage.removeItem(refreshTokenKey),
};

export const sessionAccessTokenStorage = {
  write: (token) => sessionStorage.setItem(accessTokenKey, token),
  read: () => sessionStorage.getItem(accessTokenKey),
  delete: () => sessionStorage.removeItem(accessTokenKey),
};

export const activeAccessToken = () =>
  sessionAccessTokenStorage.read() ?? accessTokenStorage.read();

export const deleteTokensOnLogout = () => {
  if (sessionAccessTokenStorage.read()) {
    sessionAccessTokenStorage.delete();
  } else {
    accessTokenStorage.delete();
    refreshTokenStorage.delete();
  }
};

export const deleteAllTokens = () => {
  sessionAccessTokenStorage.delete();
  accessTokenStorage.delete();
  refreshTokenStorage.delete();
};
