import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query GetMindwareservicesProducts(
    $skip: Number!
    $limit: Number!
    $searchString: String
    $isMultiuser: Boolean
    $productLine: String
    $usageType: String
    $materialGroup: String
    $materialType: String
    $contractTerm: String
  ) {
    getMindwareservicesProducts(
      skip: $skip
      limit: $limit
      searchString: $searchString
      isMultiuser: $isMultiuser
      productLine: $productLine
      usageType: $usageType
      materialGroup: $materialGroup
      materialType: $materialType
      contractTerm: $contractTerm
    ) {
      documentsCount
      documents {
        _id
        name
        materialDescription
        isRenewal
        canOrder
        discontinued
        isMultiuser
        isSwitch
        materialNumber
        comingFrom
        costPrice
        discount
        orderingSystem
        startDate
        endDate
        productGroup
        productLineDescription
        usageType
        materialType
        contractTerm
        renewalRef
        renewalTripleRef
        validFrom
        createdAt
      }
    }
  }
`;

export const GET_PRODUCT_LINES = gql`
  query GetMindwareservicesProductLines($skip: Number!, $limit: Number!, $searchString: String) {
    getMindwareservicesProductLines(skip: $skip, limit: $limit, searchString: $searchString) {
      documentsCount
      documents {
        code
        name
      }
    }
  }
`;
