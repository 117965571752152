import { gql } from '@apollo/client';

export const GET_BASKET = gql`
  query GetMindwareservicesBasket {
    getMindwareservicesBasket {
      _id
      cost
      products {
        quantity
        product {
          _id
          name
          materialDescription
          isRenewal
          canOrder
          discontinued
          isMultiuser
          isSwitch
          materialNumber
          comingFrom
          costPrice
          discount
          orderingSystem
          startDate
          endDate
          productGroup
          productLineDescription
          usageType
          materialGroup
          materialType
          contractTerm
          renewalRef
          renewalTripleRef
          validFrom
          createdAt
        }
      }
    }
  }
`;
