import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import CustomerListResults from '../../components/customer/CustomerListResults';
import { GET_CUSTOMERS } from '../../graphql/queries/CustomerQueries';
import CustomerListToolbar from '../../components/customer/CustomerListToolbar';
import CustomerAddDialog from '../../components/customer/CustomerAddDialog';
import { ADD_CUSTOMER, UPDATE_CUSTOMER } from '../../graphql/mutations/CustomerMutations';

const INIT_SKIP = 0;
const INIT_LIMIT = 10;

const ResellerCustomerList = () => {
  const [skip, setSkip] = useState(INIT_SKIP);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [searchText, setSearchText] = useState(undefined);

  const [addCustomerOpen, setAddCustomerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const {
    data: getCustomerData,
    fetchMore: fetchMoreCustomerData,
    refetch: refetchCustomerData,
    called: calledCustomerData,
    error: errorCustomerData,
  } = useQuery(GET_CUSTOMERS, {
    variables: {
      searchString: searchText,
      skip: INIT_SKIP,
      limit: INIT_LIMIT,
    },
  });
  const [addCustomer, { data: addCustomerData, error: errorAddCustomer }] =
    useMutation(ADD_CUSTOMER);

  const [
    updateCustomer,
    { data: updateCustomerData, error: errorUpdateCustomer, loading: loadingUpdateCustomer },
  ] = useMutation(UPDATE_CUSTOMER);

  useEffect(() => {
    if (calledCustomerData) {
      const docCount = getCustomerData?.getMindwareservicesCustomers?.documentsCount ?? 0;
      const currentCount = getCustomerData?.getMindwareservicesCustomers?.documents?.length ?? 0;
      const skipLimit = skip + limit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreCustomerData({
          variables: {
            searchString: searchText,
            skip: skip,
            limit: limit,
          },
        });
      }
    }
  }, [skip]);

  useEffect(() => {
    if (calledCustomerData) {
      refetchCustomerData({
        searchString: searchText,
        skip: skip,
        limit: limit,
      });
    }
  }, [searchText, limit]);

  useEffect(() => {
    if (errorCustomerData) {
      setSnackbarMessage(errorCustomerData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorCustomerData]);

  useEffect(() => {
    if (errorAddCustomer) {
      setSnackbarMessage(errorAddCustomer.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (addCustomerData) {
      setSnackbarMessage('Customer has been added');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      refetchCustomerData();
    }
  }, [addCustomerData, errorAddCustomer]);

  useEffect(() => {
    if (errorUpdateCustomer) {
      setSnackbarMessage(errorUpdateCustomer.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (updateCustomerData) {
      setSnackbarMessage('Customer has been updated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      refetchCustomerData();
    }
  }, [updateCustomerData, errorUpdateCustomer]);

  const handleAddCustomer = (values) => {
    addCustomer({
      variables: {
        params: values,
      },
    });
  };

  const handleUpdateCustomer = (id, values) => {
    updateCustomer({
      variables: {
        id: id,
        params: values,
      },
    });
  };

  const handleSetLimit = (limit) => {
    setLimit(limit);
    setSkip(INIT_SKIP);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    setSkip(INIT_SKIP);
  };

  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar
            onAdd={() => setAddCustomerOpen(true)}
            onSearch={handleSearch}
            search={searchText}
          />
          <CustomerListResults
            sx={{ mt: 2 }}
            customers={getCustomerData?.getMindwareservicesCustomers?.documents ?? []}
            count={getCustomerData?.getMindwareservicesCustomers?.documentsCount ?? 0}
            skip={skip}
            setSkip={setSkip}
            limit={limit}
            setLimit={handleSetLimit}
            updateCustomer={handleUpdateCustomer}
            updateCustomerLoading={loadingUpdateCustomer}
          />
        </Container>
      </Box>
      <CustomerAddDialog
        open={addCustomerOpen}
        setOpen={setAddCustomerOpen}
        onSubmit={handleAddCustomer}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ResellerCustomerList;
