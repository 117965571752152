import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import useQueryParams from '../../utils/useQueryParams';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../graphql/mutations';
import removeEmptyStrings from '../../utils/removeEmpty';

const ResetPassword = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [
    resetPassword,
    { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError },
  ] = useMutation(RESET_PASSWORD);

  useEffect(() => {
    if (resetPasswordError) {
      setSnackbarMessage(resetPasswordError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (resetPasswordData) {
      navigate('/login', { replace: true });
    }
  }, [resetPasswordData, resetPasswordError]);

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            validateOnMount
            initialValues={{
              newPassword: '',
              newPasswordConfirm: '',
            }}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string().max(255).required('Password is required'),
              newPasswordConfirm: Yup.string()
                .max(255)
                .required('Password is required')
                .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
            })}
            onSubmit={(values) => {
              const cleanValues = removeEmptyStrings(values);
              resetPassword({
                variables: {
                  params: {
                    password: cleanValues?.newPassword,
                    passwordResetToken: queryParams?.get('reset_token'),
                  },
                },
              });
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Reset your password
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Set a new password for your account.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.newPassword}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.newPasswordConfirm && errors.newPasswordConfirm)}
                  fullWidth
                  helperText={touched.newPasswordConfirm && errors.newPasswordConfirm}
                  label="Confirm New Password"
                  margin="normal"
                  name="newPasswordConfirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.newPasswordConfirm}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={resetPasswordLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/login" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;
