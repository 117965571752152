import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ProductDetailsDialog from '../product/ProductDetailsDialog';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import BasketAddDialog from './BasketAddDialog';
import Decimal from 'decimal.js';

const BasketListResults = ({ basketItems, onDelete, onUpdate, totalCost, ...rest }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openBasketItemUpdate, setOpenBasketItemUpdate] = useState(false);
  const [openBasketItemDetails, setOpenBasketItemDetails] = useState(false);

  const handleShowBasketItemDetails = (basketItem) => {
    if (basketItem) {
      setSelectedItem(basketItem);
      setOpenBasketItemDetails(true);
    }
  };

  const handleShowBasketItemEdit = (basketItem) => {
    if (basketItem) {
      setSelectedItem(basketItem);
      setOpenBasketItemUpdate(true);
    }
  };

  const handleUpdateItem = (basketItem) => {
    if (basketItem) {
      onUpdate({ product: basketItem.product, quantity: basketItem.quantity });
    }
  };

  const handleDeleteItem = (basketItem) => {
    if (basketItem) {
      onDelete({ product: basketItem?.product?._id });
    }
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>MaterialNumber(SKU)</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Total Price</TableCell>
                  {onDelete || onUpdate ? <TableCell padding="checkbox" /> : null}
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {basketItems
                  ? basketItems.map((basketItem) => (
                      <TableRow hover key={basketItem.product?._id}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {basketItem.product?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {basketItem.product?.materialNumber}
                          </Typography>
                        </TableCell>
                        <TableCell>{new Decimal(basketItem.quantity ?? 0).toFixed(0)}</TableCell>
                        <TableCell>
                          $
                          {basketItem?.price ??
                            new Decimal(basketItem.product?.costPrice ?? 0)
                              .mul(basketItem.quantity ?? 0)
                              .toFixed(2)}
                        </TableCell>
                        {onDelete || onUpdate ? (
                          <TableCell padding="checkbox">
                            <Box display={'flex'}>
                              {onUpdate ? (
                                <IconButton
                                  size="small"
                                  sx={{ marginRight: 2 }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleShowBasketItemEdit(basketItem);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              ) : null}
                              {onDelete ? (
                                <IconButton
                                  size="small"
                                  sx={{ marginRight: 2, color: 'error.main' }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteItem(basketItem);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        ) : null}
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            sx={{ marginRight: 2 }}
                            onClick={(event) => handleShowBasketItemDetails(basketItem)}
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">Order Total</Typography>
                  </TableCell>
                  <TableCell padding={'checkbox'} />
                  <TableCell>
                    <Typography variant="subtitle2">
                      {basketItems?.reduce(
                        (totalQuantity, currentItem) =>
                          new Decimal(currentItem.quantity ?? 0).add(totalQuantity).toFixed(0),
                        0,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      $
                      {totalCost ??
                        basketItems?.reduce(
                          (totalCostPrice, currentItem) =>
                            new Decimal(currentItem.price ?? currentItem.product?.costPrice ?? 0)
                              .mul(currentItem.quantity ?? 0)
                              .add(totalCostPrice)
                              .toFixed(2),
                          0,
                        )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <BasketAddDialog
        open={openBasketItemUpdate}
        setOpen={setOpenBasketItemUpdate}
        product={selectedItem?.product}
        quantity={selectedItem?.quantity}
        onUpdate={handleUpdateItem}
      />
      <ProductDetailsDialog
        setOpen={setOpenBasketItemDetails}
        open={openBasketItemDetails}
        product={selectedItem?.product}
      />
    </>
  );
};

BasketListResults.propTypes = {
  basketItems: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  totalCost: PropTypes.number,
};

export default BasketListResults;
