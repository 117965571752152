import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation PostAuthenticationLocal($params: postAuthenticationLocalRequest) {
    postAuthenticationLocal(params: $params) {
      userId
      accessToken
      refreshToken
    }
  }
`;

export const REGISTER = gql`
  mutation PostAuthenticationLocalNew($params: postAuthenticationLocalNewRequest) {
    postAuthenticationLocalNew(params: $params) {
      userId
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation PostAuthenticationForgotpassword($params: postAuthenticationForgotpasswordRequest) {
    postAuthenticationForgotpassword(params: $params) {
      result
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation PostAuthenticationResetpassword($params: postAuthenticationResetpasswordRequest) {
    postAuthenticationResetpassword(params: $params) {
      result
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation PostAuthenticationLocalChangepassword(
    $params: postAuthenticationLocalChangepasswordRequest
  ) {
    postAuthenticationLocalChangepassword(params: $params) {
      result
    }
  }
`;

export const LOGOUT = gql`
  mutation PostAuthenticationLogout {
    postAuthenticationLogout {
      result
    }
  }
`;

export const RENEW = gql`
  mutation PostAuthenticationRenew($params: postAuthenticationRenewRequest) {
    postAuthenticationRenew(params: $params) {
      accessToken
      refreshToken
    }
  }
`;
