import { v4 as uuid } from 'uuid';

const orders = [
  {
    _id: uuid(),
    ref: 'CDD1049',
    amount: 30,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct1',
        amount: 15,
        quantity: 2,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@example.com',
      csn: 3044283097,
    },
    reseller: {
      _id: uuid(),
      name: 'Cao Yu',
      email: 'cao.yu@example.com',
      csn: 7123515711,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'pending',
  },
  {
    _id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct2',
        amount: 10.1,
        quantity: 1,
      },
      {
        _id: uuid(),
        name: 'TestProduct3',
        amount: 5,
        quantity: 3,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Cao Yu',
      email: 'cao.yu@example.com',
      csn: 7123515711,
    },
    reseller: {
      _id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@example.com',
      csn: 3044283097,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'paid',
  },
  {
    _id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct4',
        amount: 10.99,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Alexa Richardson',
      email: 'alexa.richardson@example.com',
      csn: 7706352682,
    },
    reseller: {
      _id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@example.com',
      csn: 3044283097,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'completed',
  },
  {
    _id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct1',
        amount: 96.43,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Anje Keizer',
      email: 'anje.keizer@example.com',
      csn: 9086913242,
    },
    reseller: {
      _id: uuid(),
      name: 'Alexa Richardson',
      email: 'alexa.richardson@example.com',
      csn: 7706352682,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'pending',
  },
  {
    _id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct2',
        amount: 32.54,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Clarke Gillebert',
      email: 'clarke.gillebert@example.com',
      csn: 9723334106,
    },
    reseller: {
      _id: uuid(),
      name: 'Anje Keizer',
      email: 'anje.keizer@example.com',
      csn: 9086913242,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'paid',
  },
  {
    _id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct2',
        amount: 6.76,
        quantity: 1,
      },
      {
        _id: uuid(),
        name: 'TestProduct4',
        amount: 10,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Adam Denisov',
      email: 'adam.denisov@example.com',
      csn: 8586023409,
    },
    reseller: {
      _id: uuid(),
      name: 'Clarke Gillebert',
      email: 'clarke.gillebert@example.com',
      csn: 9723334106,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'paid',
  },
  {
    _id: uuid(),
    ref: 'CDD1049',
    amount: 30,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct1',
        amount: 15,
        quantity: 2,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@example.com',
      csn: 3044283097,
    },
    reseller: {
      _id: uuid(),
      name: 'Cao Yu',
      email: 'cao.yu@example.com',
      csn: 7123515711,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'pending',
  },
  {
    _id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct2',
        amount: 10.1,
        quantity: 1,
      },
      {
        _id: uuid(),
        name: 'TestProduct3',
        amount: 5,
        quantity: 3,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Cao Yu',
      email: 'cao.yu@example.com',
      csn: 7123515711,
    },
    reseller: {
      _id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@example.com',
      csn: 3044283097,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'paid',
  },
  {
    _id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct4',
        amount: 10.99,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Alexa Richardson',
      email: 'alexa.richardson@example.com',
      csn: 7706352682,
    },
    reseller: {
      _id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@example.com',
      csn: 3044283097,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'completed',
  },
  {
    _id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct1',
        amount: 96.43,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Anje Keizer',
      email: 'anje.keizer@example.com',
      csn: 9086913242,
    },
    reseller: {
      _id: uuid(),
      name: 'Alexa Richardson',
      email: 'alexa.richardson@example.com',
      csn: 7706352682,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'pending',
  },
  {
    _id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct2',
        amount: 32.54,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Clarke Gillebert',
      email: 'clarke.gillebert@example.com',
      csn: 9723334106,
    },
    reseller: {
      _id: uuid(),
      name: 'Anje Keizer',
      email: 'anje.keizer@example.com',
      csn: 9086913242,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'paid',
  },
  {
    _id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    products: [
      {
        _id: uuid(),
        name: 'TestProduct2',
        amount: 6.76,
        quantity: 1,
      },
      {
        _id: uuid(),
        name: 'TestProduct4',
        amount: 10,
        quantity: 1,
      },
    ],
    customer: {
      _id: uuid(),
      name: 'Adam Denisov',
      email: 'adam.denisov@example.com',
      csn: 8586023409,
    },
    reseller: {
      _id: uuid(),
      name: 'Clarke Gillebert',
      email: 'clarke.gillebert@example.com',
      csn: 9723334106,
    },
    createdAt: '2011-10-05T14:48:00.000Z',
    status: 'paid',
  },
];

export default orders;
