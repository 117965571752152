import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import removeEmptyStrings from '../../utils/removeEmpty';
import CountrySelect from '../CountrySelect';
import LanguageSelect from '../LanguageSelect';

const ContractAddManagerDialog = ({ open, setOpen, onSubmit, currentValues }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        firstName: currentValues ? currentValues.firstName : '',
        lastName: currentValues ? currentValues.lastName : '',
        email: currentValues ? currentValues.email : '',
        countryCode: currentValues ? currentValues.countryCode : '',
        language: currentValues ? currentValues.language : '',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(35).required('First name is required'),
        lastName: Yup.string().max(35).required('Last name is required'),
        email: Yup.string().email('Must be a valid email').max(80).required('Email is required'),
        countryCode: Yup.string().max(2),
        language: Yup.string().max(2),
      })}
      onSubmit={(values, formikHelpers) => {
        const cleanValues = removeEmptyStrings(values);
        handleClose();
        onSubmit(cleanValues);
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={(event, reason) => {
              if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
              handleClose();
              resetForm();
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
            scroll="paper"
          >
            <DialogTitle>Add Contract Manager</DialogTitle>
            <DialogContent dividers>
              <TextField
                error={Boolean(touched?.firstName && errors?.firstName)}
                fullWidth
                helperText={touched?.firstName && errors?.firstName}
                label="First Name*"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.firstName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.lastName && errors?.lastName)}
                fullWidth
                helperText={touched?.lastName && errors?.lastName}
                label="Last Name*"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.lastName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.email && errors?.email)}
                fullWidth
                helperText={touched?.email && errors?.email}
                label="Email Address*"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values?.email}
                variant="outlined"
              />
              <CountrySelect
                fullWidth
                onChange={(e, value) => setFieldValue('countryCode', value)}
                value={values?.countryCode}
                TextFieldProps={{
                  error: Boolean(touched?.countryCode && errors?.countryCode),
                  helperText: touched?.countryCode && errors?.countryCode,
                  label: 'Country Code',
                  margin: 'normal',
                  name: 'countryCode',
                  onBlur: handleBlur,
                  variant: 'outlined',
                  type: 'text',
                }}
                disableClearable
              />
              <LanguageSelect
                fullWidth
                onChange={(e, value) => setFieldValue('language', value)}
                value={values?.language}
                TextFieldProps={{
                  error: Boolean(touched?.language && errors?.language),
                  helperText: touched?.language && errors?.language,
                  label: 'Language',
                  margin: 'normal',
                  name: 'language',
                  onBlur: handleBlur,
                  variant: 'outlined',
                  type: 'text',
                }}
                disableClearable
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={isSubmitting} onClick={handleSubmit} color="primary" autoFocus>
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

ContractAddManagerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentValues: PropTypes.object,
};

export default ContractAddManagerDialog;
