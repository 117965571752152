import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import BasketListResults from '../basket/BasketListResults';
import { ExpandMore } from '@material-ui/icons';

const OrderDetailsDialog = ({ open, setOpen, order, onSubmit, submitLoading }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
        handleClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Order Details
        {order?.transactionId ? ` | ${order?.transactionId}` : ''}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography variant={'h5'} color={'textPrimary'}>
              Order Total Cost:
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              ${order?.cost}
            </Typography>
          </Grid>
          {order?.processingMessage ? (
            <>
              <Grid item xs={12} md={3}>
                <Typography variant={'h5'} color={'textPrimary'}>
                  Processing Message:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  {order?.processingMessage}
                </Typography>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={'h4'} color={'textPrimary'}>
                  Customer
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={order?.customer?.CSN ?? undefined}
                  margin="dense"
                  id="CSN"
                  label="CSN"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    <Typography variant={'h5'} color={'textPrimary'}>
                      Account
                    </Typography>
                    <TextField
                      value={order?.customer?.account?.name ?? undefined}
                      margin="dense"
                      id="name"
                      label="Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.altName ?? undefined}
                      margin="dense"
                      id="altName"
                      label="Alt Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.addressLine1 ?? undefined}
                      margin="dense"
                      id="addressLine1"
                      label="Address Line 1"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.addressLine2 ?? undefined}
                      margin="dense"
                      id="addressLine2"
                      label="Address Line 2"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.addressLine3 ?? undefined}
                      margin="dense"
                      id="addressLine3"
                      label="Address Line 3"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.city ?? undefined}
                      margin="dense"
                      id="city"
                      label="City"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.postalCode ?? undefined}
                      margin="dense"
                      id="postalCode"
                      label="Postal Code"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.stateProvinceCode ?? undefined}
                      margin="dense"
                      id="stateProvinceCode"
                      label="State Province Code"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.countryCode ?? undefined}
                      margin="dense"
                      id="countryCode"
                      label="Country Code"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      value={order?.customer?.account?.phoneNumber ?? undefined}
                      margin="dense"
                      id="phoneNumber"
                      label="Phone Number"
                      type="text"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {order?.contractManager ? (
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h4'} color={'textPrimary'}>
                    Contract Manager
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    value={order?.contractManager?.firstName ?? undefined}
                    margin="dense"
                    id="firstName"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    value={order?.contractManager?.lastName ?? undefined}
                    margin="dense"
                    id="lastName"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    value={order?.contractManager?.email ?? undefined}
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    value={order?.contractManager?.countryCode ?? undefined}
                    margin="dense"
                    id="countryCode"
                    label="Country Code"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    value={order?.contractManager?.language ?? undefined}
                    margin="dense"
                    id="language"
                    label="Language"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={'h4'} color={'textPrimary'}>
                  Products
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BasketListResults basketItems={order?.products ?? []} totalCost={order?.cost} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {onSubmit ? (
          <Button
            disabled={submitLoading}
            variant={'contained'}
            color={'primary'}
            onClick={onSubmit}
            endIcon={submitLoading ? <CircularProgress color={'inherit'} size={16} /> : undefined}
          >
            Submit
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

OrderDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  order: PropTypes.object,
  onSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
};

export default OrderDetailsDialog;
