import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import removeEmptyStrings from '../../utils/removeEmpty';

const SettingsUserAddDialog = ({ open, setOpen, onSubmit }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <Formik
        validateOnMount
        initialValues={{
          fullname: '',
          email: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          fullname: Yup.string().max(255).required('Full name is required'),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
        })}
        onSubmit={(values) => {
          const cleanValues = removeEmptyStrings(values);
          handleClose();
          onSubmit(cleanValues);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add User</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill in the fields to add a user.</DialogContentText>
              <TextField
                error={Boolean(touched.fullname && errors.fullname)}
                fullWidth
                helperText={touched.fullname && errors.fullname}
                label="Full Name"
                margin="normal"
                name="fullname"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.fullname}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={isSubmitting} type={'submit'} color="primary" autoFocus>
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

SettingsUserAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SettingsUserAddDialog;
