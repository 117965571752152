import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import ContractListResults from '../../components/contract/ContractListResults';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_CONTRACT_OPPORTUNITIES, GET_CONTRACTS } from '../../graphql/queries/ContractQueries';
import ContractListToolbar from '../../components/contract/ContractListToolbar';
import {
  FINALIZE_ADD_SEATS_ORDER,
  FINALIZE_RENEW_ORDER,
  SUBMIT_ADD_SEATS_ORDER,
  SUBMIT_RENEW_ORDER,
} from '../../graphql/mutations/OrderMutations';
import OrderDetailsDialog from '../../components/order/OrderDetailsDialog';

const INIT_SKIP = 0;
const INIT_LIMIT = 10;

const ResellerContractList = () => {
  const navigate = useNavigate();
  const [skip, setSkip] = useState(INIT_SKIP);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [searchText, setSearchText] = useState(undefined);

  const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);
  const [orderDetailsRenewOpen, setOrderDetailsRenewOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const {
    data: getContractData,
    fetchMore: fetchMoreContractData,
    refetch: refetchContractData,
    called: calledContractData,
    error: errorContractData,
  } = useQuery(GET_CONTRACTS, {
    variables: {
      searchString: searchText,
      skip: INIT_SKIP,
      limit: INIT_LIMIT,
      sort: ['-createdAt'],
    },
  });

  const [
    getContractOpportunities,
    {
      data: contractOpportunitiesData,
      error: contractOpportunitiesError,
      loading: contractOpportunitiesLoading,
    },
  ] = useLazyQuery(GET_CONTRACT_OPPORTUNITIES, {
    fetchPolicy: 'network-only',
  });

  const [
    finalizeAddSeatsOrder,
    {
      data: finalizeAddSeatsOrderData,
      error: finalizeAddSeatsOrderError,
      loading: finalizeAddSeatsOrderLoading,
    },
  ] = useMutation(FINALIZE_ADD_SEATS_ORDER);

  const [
    finalizeRenewOrder,
    {
      data: finalizeRenewOrderData,
      error: finalizeRenewOrderError,
      loading: finalizeRenewOrderLoading,
    },
  ] = useMutation(FINALIZE_RENEW_ORDER);

  const [
    submitAddSeatsOrder,
    { error: submitAddSeatsOrderError, loading: submitAddSeatsOrderLoading },
  ] = useMutation(SUBMIT_ADD_SEATS_ORDER);

  const [submitRenewOrder, { error: submitRenewOrderError, loading: submitRenewOrderLoading }] =
    useMutation(SUBMIT_RENEW_ORDER);

  useEffect(() => {
    if (calledContractData) {
      const docCount = getContractData?.getMindwareservicesContracts?.documentsCount ?? 0;
      const currentCount = getContractData?.getMindwareservicesContracts?.documents?.length ?? 0;
      const skipLimit = skip + limit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreContractData({
          variables: {
            searchString: searchText,
            skip: skip,
            limit: limit,
          },
        });
      }
    }
  }, [skip]);

  useEffect(() => {
    if (calledContractData) {
      refetchContractData({
        searchString: searchText,
        skip: skip,
        limit: limit,
      });
    }
  }, [limit, searchText]);

  useEffect(() => {
    if (errorContractData) {
      setSnackbarMessage(errorContractData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorContractData]);

  useEffect(() => {
    if (finalizeAddSeatsOrderError) {
      setSnackbarMessage(finalizeAddSeatsOrderError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [finalizeAddSeatsOrderError]);

  useEffect(() => {
    if (finalizeRenewOrderError) {
      setSnackbarMessage(finalizeRenewOrderError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [finalizeRenewOrderError]);

  useEffect(() => {
    if (submitAddSeatsOrderError) {
      setSnackbarMessage(submitAddSeatsOrderError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [submitAddSeatsOrderError]);

  useEffect(() => {
    if (submitRenewOrderError) {
      setSnackbarMessage(submitRenewOrderError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [submitRenewOrderError]);

  useEffect(() => {
    if (contractOpportunitiesError) {
      setSnackbarMessage(contractOpportunitiesError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [contractOpportunitiesError]);

  const handleSetLimit = (limit) => {
    setLimit(limit);
    setSkip(INIT_SKIP);
  };

  const handleContractSearch = (text) => {
    setSearchText(text);
    setSkip(INIT_SKIP);
  };

  const handleFinalizeAddSeatsOrder = (values) => {
    finalizeAddSeatsOrder({
      variables: {
        params: values,
      },
    }).then(() => {
      setOrderDetailsOpen(true);
    });
  };

  const handleFinalizeRenewOrder = (values) => {
    finalizeRenewOrder({
      variables: {
        params: values,
      },
    }).then(() => {
      setOrderDetailsRenewOpen(true);
    });
  };

  const handleSubmitAddSeatsOrder = () => {
    submitAddSeatsOrder({
      variables: {
        order: finalizeAddSeatsOrderData?.FinalizeAddSeatsOrder?._id,
      },
    }).then(() => {
      setOrderDetailsOpen(false);
      navigate('/reseller/orders');
    });
  };

  const handleSubmitRenewOrder = () => {
    submitRenewOrder({
      variables: {
        order: finalizeRenewOrderData?.FinalizeRenewal?._id,
      },
    }).then(() => {
      setOrderDetailsRenewOpen(false);
      navigate('/reseller/orders');
    });
  };

  const handleGetOpportunities = (contract) => {
    getContractOpportunities({
      variables: {
        contract: contract,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Contracts</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <ContractListToolbar onSearch={handleContractSearch} search={searchText} />
          <ContractListResults
            sx={{ mt: 2 }}
            contracts={getContractData?.getMindwareservicesContracts?.documents ?? []}
            count={getContractData?.getMindwareservicesContracts?.documentsCount ?? 0}
            skip={skip}
            setSkip={setSkip}
            limit={limit}
            setLimit={handleSetLimit}
            finalizeAddSeatsOrder={handleFinalizeAddSeatsOrder}
            loadingFinalizeAddSeatsOrder={finalizeAddSeatsOrderLoading}
            finalizeRenewOrder={handleFinalizeRenewOrder}
            loadingFinalizeRenewOrder={finalizeRenewOrderLoading}
            opportunity={contractOpportunitiesData?.GetContractsOpportunities?.opportunity}
            getOpportunities={handleGetOpportunities}
            loadingGetOpportunities={contractOpportunitiesLoading}
          />
        </Container>
      </Box>
      <OrderDetailsDialog
        setOpen={setOrderDetailsOpen}
        open={orderDetailsOpen}
        order={finalizeAddSeatsOrderData?.FinalizeAddSeatsOrder}
        onSubmit={handleSubmitAddSeatsOrder}
        submitLoading={submitAddSeatsOrderLoading}
      />
      <OrderDetailsDialog
        setOpen={setOrderDetailsRenewOpen}
        open={orderDetailsRenewOpen}
        order={finalizeRenewOrderData?.FinalizeRenewal}
        onSubmit={handleSubmitRenewOrder}
        submitLoading={submitRenewOrderLoading}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResellerContractList;
