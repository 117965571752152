import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Card, CardContent, Container, Divider, Snackbar } from '@material-ui/core';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import BasketListResults from '../../components/basket/BasketListResults';
import { GET_BASKET } from '../../graphql/queries/BasketQueries';
import BasketToolbar from '../../components/basket/BasketToolbar';
import BasketButtons from '../../components/basket/BasketButtons';
import CustomerListResults from '../../components/customer/CustomerListResults';
import { GET_CUSTOMERS } from '../../graphql/queries/CustomerQueries';
import CustomerListToolbar from '../../components/customer/CustomerListToolbar';
import { ADD_CUSTOMER, UPDATE_CUSTOMER } from '../../graphql/mutations/CustomerMutations';
import CustomerAddDialog from '../../components/customer/CustomerAddDialog';
import BasketContractRadio from '../../components/basket/BasketContractRadio';
import {
  ADD_TO_BASKET,
  DELETE_FROM_BASKET,
  EMPTY_BASKET,
} from '../../graphql/mutations/BasketMutations';
import ContractListResults from '../../components/contract/ContractListResults';
import { GET_CONTRACT_MANAGERS, GET_CONTRACTS } from '../../graphql/queries/ContractQueries';
import ContractListToolbar from '../../components/contract/ContractListToolbar';
import { FINALIZE_ORDER, SUBMIT_ORDER } from '../../graphql/mutations/OrderMutations';
import OrderDetailsDialog from '../../components/order/OrderDetailsDialog';
import { useNavigate } from 'react-router-dom';
import ContractAddManagerDialog from '../../components/contract/ContractAddManagerDialog';
import { format } from 'date-fns';
import ContractManagerListToolbar from '../../components/contract/ContractManagerListToolbar';
import ContractManagerListResults from '../../components/contract/ContractManagerListResults';
import BasketContractManagerRadio from '../../components/basket/BasketContractManagerRadio';
import TextField from '@material-ui/core/TextField';

const INIT_SKIP = 0;
const INIT_LIMIT = 5;

const ResellerBasket = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customerSkip, setCustomerSkip] = useState(INIT_SKIP);
  const [customerLimit, setCustomerLimit] = useState(INIT_LIMIT);
  const [searchCustomerText, setSearchCustomerText] = useState(undefined);
  const [addCustomerOpen, setAddCustomerOpen] = useState(false);

  const [existingContract, setExistingContract] = useState(false);

  const [selectedContract, setSelectedContract] = useState();
  const [contractSkip, setContractSkip] = useState(INIT_SKIP);
  const [contractLimit, setContractLimit] = useState(INIT_LIMIT);
  const [searchContractText, setSearchContractText] = useState(undefined);

  const [existingContractManager, setExistingContractManager] = useState(false);

  const [selectedContractManager, setSelectedContractManager] = useState();
  const [contractManagerSkip, setContractManagerSkip] = useState(INIT_SKIP);
  const [contractManagerLimit, setContractManagerLimit] = useState(INIT_LIMIT);
  const [searchContractManagerText, setSearchContractManagerText] = useState(undefined);

  const [contractManagerOpen, setContractManagerOpen] = useState(false);
  const [contractManagerValues, setContractManagerValues] = useState(undefined);

  const [poText, setPoText] = useState();

  const [startDate, setStartDate] = useState(new Date());

  const { data: dataGetBasket, error: errorGetBasket } = useQuery(GET_BASKET);
  const [emptyBasket, { data: dataEmptyBasket, error: errorEmptyBasket }] = useMutation(
    EMPTY_BASKET,
    { refetchQueries: [{ query: GET_BASKET }] },
  );
  const [addToBasket, { error: errorAddToBasket }] = useMutation(ADD_TO_BASKET, {
    refetchQueries: [{ query: GET_BASKET }],
  });
  const [deleteFromBasket, { error: errorDeleteFromBasket }] = useMutation(DELETE_FROM_BASKET, {
    refetchQueries: [{ query: GET_BASKET }],
  });

  const {
    data: getCustomerData,
    fetchMore: fetchMoreCustomerData,
    refetch: refetchCustomerData,
    called: calledCustomerData,
    error: errorCustomerData,
  } = useQuery(GET_CUSTOMERS, {
    variables: {
      searchString: searchCustomerText,
      skip: INIT_SKIP,
      limit: INIT_LIMIT,
    },
  });

  const [addCustomer, { data: addCustomerData, error: errorAddCustomer }] =
    useMutation(ADD_CUSTOMER);

  const [
    updateCustomer,
    { data: updateCustomerData, error: errorUpdateCustomer, loading: loadingUpdateCustomer },
  ] = useMutation(UPDATE_CUSTOMER);

  const [
    getContracts,
    {
      data: getContractData,
      fetchMore: fetchMoreContractData,
      refetch: refetchContractData,
      called: calledContractData,
      error: errorContractData,
    },
  ] = useLazyQuery(GET_CONTRACTS);

  const [
    getContractManagers,
    {
      data: getContractManagersData,
      fetchMore: fetchMoreContractManagersData,
      refetch: refetchContractManagersData,
      called: calledContractManagersData,
      error: errorContractManagersData,
    },
  ] = useLazyQuery(GET_CONTRACT_MANAGERS);

  const [
    finalizeOrder,
    { data: finalizeOrderData, error: finalizeOrderError, loading: finalizeOrderLoading },
  ] = useMutation(FINALIZE_ORDER);

  const [submitOrder, { error: submitOrderError, loading: submitOrderLoading }] = useMutation(
    SUBMIT_ORDER,
    {
      refetchQueries: [{ query: GET_BASKET }],
    },
  );

  useEffect(() => {
    if (!selectedCustomer) return;
    if (calledContractData) {
      const docCount = getContractData?.getMindwareservicesContracts?.documentsCount ?? 0;
      const currentCount = getContractData?.getMindwareservicesContracts?.documents?.length ?? 0;
      const skipLimit = contractSkip + contractLimit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreContractData({
          variables: {
            searchString: searchContractText,
            skip: contractSkip,
            limit: contractLimit,
            customerId: selectedCustomer?._id,
            sort: ['-createdAt'],
          },
        });
      }
    }
  }, [contractSkip]);

  useEffect(() => {
    if (!selectedCustomer) return;
    if (calledContractManagersData) {
      const docCount =
        getContractManagersData?.getMindwareservicesCustomersContractManager
          ?.contractManagersCount ?? 0;
      const currentCount =
        getContractManagersData?.getMindwareservicesCustomersContractManager?.contractManagers
          ?.length ?? 0;
      const skipLimit = contractManagerSkip + contractManagerLimit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreContractManagersData({
          variables: {
            searchString: searchContractManagerText,
            skip: contractManagerSkip,
            limit: contractManagerLimit,
            customerId: selectedCustomer?._id,
            sort: ['-createdAt'],
          },
        });
      }
    }
  }, [contractManagerSkip]);

  useEffect(() => {
    if (calledCustomerData) {
      const docCount = getCustomerData?.getMindwareservicesCustomers?.documentsCount ?? 0;
      const currentCount = getCustomerData?.getMindwareservicesCustomers?.documents?.length ?? 0;
      const skipLimit = customerSkip + customerLimit;
      if (currentCount < docCount && currentCount < skipLimit) {
        fetchMoreCustomerData({
          variables: {
            searchString: searchCustomerText,
            skip: customerSkip,
            limit: customerLimit,
          },
        });
      }
    }
  }, [customerSkip]);

  useEffect(() => {
    if (calledCustomerData) {
      refetchCustomerData({
        searchString: searchCustomerText,
        skip: customerSkip,
        limit: customerLimit,
      });
    }
  }, [searchCustomerText, customerLimit]);

  useEffect(() => {
    if (calledContractData && selectedCustomer) {
      refetchContractData({
        searchString: searchContractText,
        skip: contractSkip,
        limit: contractLimit,
        customerId: selectedCustomer?._id,
        sort: ['-createdAt'],
      });
    } else if (selectedCustomer) {
      getContracts({
        variables: {
          searchString: searchContractText,
          skip: contractSkip,
          limit: contractLimit,
          customerId: selectedCustomer?._id,
          sort: ['-createdAt'],
        },
      });
    }
  }, [contractLimit, selectedCustomer, searchContractText]);

  useEffect(() => {
    if (calledContractManagersData && selectedCustomer) {
      refetchContractManagersData({
        searchString: searchContractManagerText,
        skip: contractManagerSkip,
        limit: contractManagerLimit,
        customerId: selectedCustomer?._id,
        sort: ['-createdAt'],
      });
    } else if (selectedCustomer) {
      getContractManagers({
        variables: {
          searchString: searchContractManagerText,
          skip: contractManagerSkip,
          limit: contractManagerLimit,
          customerId: selectedCustomer?._id,
          sort: ['-createdAt'],
        },
      });
    }
  }, [contractManagerLimit, selectedCustomer, searchContractManagerText]);

  useEffect(() => {
    setSelectedContract(undefined);
    setSelectedContractManager(undefined);
    if (!selectedCustomer) {
      setExistingContract(false);
      setExistingContractManager(false);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (!existingContract) {
      setSelectedContract(undefined);
    } else {
      setSelectedContractManager(undefined);
      setExistingContractManager(false);
    }
  }, [existingContract]);

  useEffect(() => {
    if (!existingContractManager) {
      setSelectedContractManager(undefined);
    }
  }, [existingContractManager]);

  useEffect(() => {
    if (errorContractData) {
      setSnackbarMessage(errorContractData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorContractData]);

  useEffect(() => {
    if (errorContractManagersData) {
      setSnackbarMessage(errorContractManagersData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorContractManagersData]);

  useEffect(() => {
    if (errorCustomerData) {
      setSnackbarMessage(errorCustomerData.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorCustomerData]);

  useEffect(() => {
    if (errorAddCustomer) {
      setSnackbarMessage(errorAddCustomer.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (addCustomerData) {
      setSnackbarMessage('Customer has been added');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      refetchCustomerData();
    }
  }, [addCustomerData, errorAddCustomer]);

  useEffect(() => {
    if (errorUpdateCustomer) {
      setSnackbarMessage(errorUpdateCustomer.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (updateCustomerData) {
      setSnackbarMessage('Customer has been updated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      refetchCustomerData();
    }
  }, [updateCustomerData, errorUpdateCustomer]);

  useEffect(() => {
    if (errorGetBasket) {
      setSnackbarMessage(errorGetBasket.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorGetBasket]);

  useEffect(() => {
    if (errorAddToBasket) {
      setSnackbarMessage(errorAddToBasket.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorAddToBasket]);

  useEffect(() => {
    if (errorDeleteFromBasket) {
      setSnackbarMessage(errorDeleteFromBasket.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [errorDeleteFromBasket]);

  useEffect(() => {
    if (finalizeOrderError) {
      setSnackbarMessage(finalizeOrderError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [finalizeOrderError]);

  useEffect(() => {
    if (submitOrderError) {
      setSnackbarMessage(submitOrderError.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [submitOrderError]);

  useEffect(() => {
    if (errorEmptyBasket) {
      setSnackbarMessage(errorEmptyBasket.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (dataEmptyBasket) {
      setSnackbarMessage('Basket emptied');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [errorEmptyBasket, dataEmptyBasket]);

  const handleSetContractLimit = (limit) => {
    setContractLimit(limit);
    setContractSkip(INIT_SKIP);
  };

  const handleSetSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setContractSkip(INIT_SKIP);
    setContractManagerSkip(INIT_SKIP);
  };

  const handleAddCustomer = (values) => {
    addCustomer({
      variables: {
        params: values,
      },
    });
  };

  const handleUpdateCustomer = (id, values) => {
    updateCustomer({
      variables: {
        id: id,
        params: values,
      },
    });
  };

  const handleSetCustomerLimit = (limit) => {
    setCustomerLimit(limit);
    setCustomerSkip(INIT_SKIP);
  };

  const handleCustomerSearch = (text) => {
    setSearchCustomerText(text);
    setCustomerSkip(INIT_SKIP);
  };

  const handleSetSelectedContractManager = (manager) => {
    setSelectedContractManager(manager);
  };

  const handleSetContractManagerLimit = (limit) => {
    setContractManagerLimit(limit);
    setContractManagerSkip(INIT_SKIP);
  };

  const handleContractManagerSearch = (text) => {
    setSearchContractManagerText(text);
    setContractManagerSkip(INIT_SKIP);
  };

  const handleContractSearch = (text) => {
    setSearchContractText(text);
    setContractSkip(INIT_SKIP);
  };

  const handleAddBasket = (values) => {
    const { product, quantity = 1 } = values;
    addToBasket({ variables: { product: product, quantity: quantity } });
  };

  const handleDeleteBasket = (values) => {
    const { product } = values;
    deleteFromBasket({ variables: { product: product } });
  };

  const handleFinalizeOrder = (managerValues) => {
    finalizeOrder({
      variables: {
        params: {
          customer: selectedCustomer?._id,
          contract: selectedContract?._id,
          startDate: format(startDate, 'yyyy-MM-dd'),
          contractManager: managerValues,
          poText: poText,
        },
      },
    }).then(() => {
      setOrderDetailsOpen(true);
    });
  };

  const handleSubmitOrder = () => {
    submitOrder({
      variables: {
        order: finalizeOrderData?.FinalizeOrder?._id,
      },
    }).then(() => {
      setOrderDetailsOpen(false);
      navigate('/reseller/orders');
    });
  };

  const handleFinalizeButton = () => {
    if (existingContract) {
      handleFinalizeOrder(undefined);
    } else if (existingContractManager) {
      handleFinalizeOrder({ _id: selectedContractManager?._id });
    } else {
      setContractManagerOpen(true);
    }
  };

  const renderActiveStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <BasketListResults
            sx={{ mt: 2 }}
            basketItems={dataGetBasket?.getMindwareservicesBasket?.products ?? []}
            onDelete={handleDeleteBasket}
            onUpdate={handleAddBasket}
          />
        );
      case 1:
        return (
          <>
            <CustomerListToolbar
              sx={{ mt: 2 }}
              onAdd={() => setAddCustomerOpen(true)}
              onSearch={handleCustomerSearch}
              search={searchCustomerText}
            />
            <CustomerListResults
              sx={{ mt: 2 }}
              customers={getCustomerData?.getMindwareservicesCustomers?.documents ?? []}
              count={getCustomerData?.getMindwareservicesCustomers?.documentsCount ?? 0}
              skip={customerSkip}
              setSkip={setCustomerSkip}
              limit={customerLimit}
              setLimit={handleSetCustomerLimit}
              selected={selectedCustomer}
              setSelected={handleSetSelectedCustomer}
              updateCustomer={handleUpdateCustomer}
              updateCustomerLoading={loadingUpdateCustomer}
            />
            <CustomerAddDialog
              open={addCustomerOpen}
              setOpen={setAddCustomerOpen}
              onSubmit={handleAddCustomer}
            />
            <Divider sx={{ mt: 2 }} />
            <BasketContractRadio
              sx={{ mt: 2 }}
              existing={existingContract}
              setExisting={setExistingContract}
              disabled={!selectedCustomer}
            />
            {existingContract ? (
              <>
                <ContractListToolbar
                  sx={{ mt: 2 }}
                  onSearch={handleContractSearch}
                  search={searchContractText}
                />
                <ContractListResults
                  sx={{ mt: 2 }}
                  contracts={getContractData?.getMindwareservicesContracts?.documents ?? []}
                  count={getContractData?.getMindwareservicesContracts?.documentsCount ?? 0}
                  skip={contractSkip}
                  setSkip={setContractSkip}
                  limit={contractLimit}
                  setLimit={handleSetContractLimit}
                  selected={selectedContract}
                  setSelected={setSelectedContract}
                />
              </>
            ) : null}
            {!existingContract ? (
              <>
                <Divider sx={{ mt: 2 }} />
                <BasketContractManagerRadio
                  sx={{ mt: 2 }}
                  existing={existingContractManager}
                  setExisting={setExistingContractManager}
                  disabled={!selectedCustomer}
                />
                {existingContractManager ? (
                  <>
                    <ContractManagerListToolbar
                      sx={{ mt: 2 }}
                      onSearch={handleContractManagerSearch}
                      search={searchContractManagerText}
                    />
                    <ContractManagerListResults
                      sx={{ mt: 2 }}
                      managers={
                        getContractManagersData?.getMindwareservicesCustomersContractManager
                          ?.contractManagers ?? []
                      }
                      count={
                        getContractManagersData?.getMindwareservicesCustomersContractManager
                          ?.contractManagersCount ?? 0
                      }
                      skip={contractManagerSkip}
                      setSkip={setContractManagerSkip}
                      limit={contractManagerLimit}
                      setLimit={handleSetContractManagerLimit}
                      selected={selectedContractManager}
                      setSelected={handleSetSelectedContractManager}
                    />
                  </>
                ) : null}
              </>
            ) : null}
            <Divider sx={{ mt: 2 }} />
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <TextField
                  sx={{ mt: 2 }}
                  size="small"
                  inputProps={{ maxLength: 35 }}
                  fullWidth
                  label="PO number"
                  margin="normal"
                  name="poText"
                  onChange={(event) =>
                    setPoText(event.target.value.trim().replace(/[^0-9a-z]/gi, ''))
                  }
                  type="text"
                  value={poText}
                  variant="outlined"
                />
              </CardContent>
            </Card>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Basket</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <BasketToolbar activeStep={activeStep} />
          {renderActiveStep(activeStep)}
          <BasketButtons
            sx={{ mt: 2 }}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            clearBasket={emptyBasket}
            finalizeOrder={handleFinalizeButton}
            submitOrder={handleSubmitOrder}
            checkoutDisabled={!dataGetBasket?.getMindwareservicesBasket?.products?.length}
            finalizeDisabled={
              finalizeOrderLoading ||
              (!(selectedCustomer && !existingContract) &&
                !(existingContract && selectedContract)) ||
              (!(selectedCustomer && !existingContractManager) &&
                !(existingContractManager && selectedContractManager))
            }
            finalizeLoading={finalizeOrderLoading}
            startDate={startDate}
            setStartDate={setStartDate}
            contractExpiration={selectedContract?.expiresOn}
          />
        </Container>
      </Box>
      <ContractAddManagerDialog
        open={contractManagerOpen}
        setOpen={setContractManagerOpen}
        onSubmit={handleFinalizeOrder}
        currentValues={contractManagerValues}
      />
      <OrderDetailsDialog
        setOpen={setOrderDetailsOpen}
        open={orderDetailsOpen}
        order={finalizeOrderData?.FinalizeOrder}
        onSubmit={handleSubmitOrder}
        submitLoading={submitOrderLoading}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ResellerBasket;
