import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetMindwareservicesOrders(
    $skip: Number!
    $limit: Number!
    $searchString: String
    $status: [String]
    $paid: Boolean
    $sort: [String]
  ) {
    getMindwareservicesOrders(
      skip: $skip
      limit: $limit
      searchString: $searchString
      status: $status
      paid: $paid
      sort: $sort
    ) {
      documentsCount
      documents {
        _id
        processingMessage
        distributorMargin
        distributorCost
        cost
        status
        transactionId
        customer {
          _id
          CSN
          account {
            name
            altName
            addressLine1
            addressLine2
            addressLine3
            city
            postalCode
            stateProvinceCode
            countryCode
            phoneNumber
          }
        }
        contractManager {
          _id
          firstName
          lastName
          email
          countryCode
          language
        }
        products {
          quantity
          product {
            _id
            name
            materialDescription
            isRenewal
            canOrder
            discontinued
            isMultiuser
            isSwitch
            materialNumber
            comingFrom
            costPrice
            discount
            orderingSystem
            startDate
            endDate
            productGroup
            productLineDescription
            usageType
            materialType
            contractTerm
            renewalRef
            renewalTripleRef
            validFrom
            createdAt
          }
          actualPrice
          price
        }
        createdAt
      }
    }
  }
`;
