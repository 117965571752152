import { gql } from '@apollo/client';

export const AUTHENTICATION_USER = gql`
  query GetAuthenticationUser {
    getAuthenticationUser {
      _id
      email
      isVerified
      createdAt
    }
  }
`;

export const IMPERSONATE_RESELLER = gql`
  query GetMindwareservicesImpersonateReseller($reseller: String!) {
    getMindwareservicesImpersonateReseller(reseller: $reseller) {
      result
    }
  }
`;
