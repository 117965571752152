import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Decimal from 'decimal.js';

const ResellerListResults = ({
  resellers,
  count,
  onDelete,
  onEdit,
  onImpersonate,
  onCreateAccount,
  skip,
  setSkip,
  limit,
  setLimit,
  ...rest
}) => {
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [rowActionAnchorEl, setRowActionAnchorEl] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleActionMenuClose = () => {
    setSelectedReseller(null);
    setRowActionAnchorEl(null);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    handleActionMenuClose();
  };

  const handleActionMenuOpen = (event, values) => {
    setSelectedReseller(values);
    setRowActionAnchorEl(event.currentTarget);
  };

  const handleRowDelete = () => {
    setConfirmationDialogOpen(true);
  };

  const handleDeleteReseller = () => {
    onDelete(selectedReseller);
    handleConfirmationDialogClose();
  };

  const handleEditReseller = () => {
    onEdit(selectedReseller);
    handleActionMenuClose();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  const handleImpersonate = () => {
    onImpersonate(selectedReseller);
    handleActionMenuClose();
  };

  const handleCreateAccount = () => {
    onCreateAccount(selectedReseller);
    handleActionMenuClose();
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>CSN</TableCell>
                  <TableCell>Registration Date</TableCell>
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {resellers
                  ? resellers.slice(skip, skip + limit).map((reseller) => (
                      <TableRow hover key={reseller._id}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {reseller.fullname}
                          </Typography>
                        </TableCell>
                        <TableCell>{reseller.email}</TableCell>
                        <TableCell>{reseller.CSN}</TableCell>
                        <TableCell>{moment(reseller.createdAt).format('DD/MM/YYYY')}</TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            onClick={(event) => handleActionMenuOpen(event, reseller)}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={pageNumber(skip, limit)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <Menu
        id="simple-menu"
        anchorEl={rowActionAnchorEl}
        keepMounted
        open={Boolean(rowActionAnchorEl)}
        onClose={handleActionMenuClose}
      >
        {selectedReseller && selectedReseller.email
          ? [
              <MenuItem key={'impersonate'} onClick={handleImpersonate}>
                Impersonate
              </MenuItem>,
              <MenuItem key={'edit'} onClick={handleEditReseller}>
                Edit
              </MenuItem>,
              <MenuItem key={'delete'} onClick={handleRowDelete}>
                Delete
              </MenuItem>,
            ]
          : selectedReseller
          ? [
              <Button
                key={'create-account'}
                size={'small'}
                variant={'contained'}
                sx={{ marginX: 1 }}
                onClick={handleCreateAccount}
              >
                Create Account
              </Button>,
              <MenuItem key={'delete'} onClick={handleRowDelete}>
                Delete
              </MenuItem>,
            ]
          : null}
      </Menu>
      <Dialog
        open={confirmationDialogOpen}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleConfirmationDialogClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete reseller?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this reseller?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationDialogClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDeleteReseller} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResellerListResults.propTypes = {
  resellers: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onImpersonate: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
};

ResellerListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default ResellerListResults;
