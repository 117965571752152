import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { accessTokenStorage, activeAccessToken, refreshTokenStorage } from '../tokenStorage';
import { setContext } from '@apollo/client/link/context';
import { RENEW } from './mutations';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: process.env['REACT_APP_CONDUIT_URL'],
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = activeAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      clientid: process.env['REACT_APP_CLIENT_ID'],
      clientsecret: process.env['REACT_APP_CLIENT_SECRET'],
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (operation?.operationName === 'postAuthenticationRenew') {
    return;
  }

  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        // case 'UNAUTHENTICATED': {
        case 401: {
          const refreshToken = refreshTokenStorage.read();

          if (refreshToken) {
            client
              .mutate({
                mutation: RENEW,
                variables: {
                  params: {
                    refreshToken: refreshToken,
                  },
                },
              })
              .then((res) => {
                const accessToken = res?.data?.postAuthenticationRenew?.accessToken;
                const refreshToken = res?.data?.postAuthenticationRenew?.refreshToken;

                accessTokenStorage.write(accessToken);
                refreshTokenStorage.write(refreshToken);

                // Modify the operation context with a new token
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: accessToken ? `Bearer ${accessToken}` : '',
                  },
                });

                //TODO :: retry not working
                // Retry the request, returning the new observable
                return forward(operation);
              })
              .catch((err) => {
                if (operation?.operationName !== 'getCmsFunctionGetMyUser') {
                  client.clearStore().finally(() => {
                    if (window.location.pathname !== '/login') {
                      window.location.replace('/login');
                    }
                  });
                }
              });
          } else {
            if (operation?.operationName !== 'getCmsFunctionGetMyUser') {
              client.clearStore().finally(() => {
                if (window.location.pathname !== '/login') {
                  window.location.replace('/login');
                }
              });
            }
          }
        }
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getCmsFunctionGetDisti: skipLimitPagination(),
        getDisti: skipLimitPagination(),
        getCmsFunctionSearchResellers: skipLimitPagination(),
        getMindwareservicesProducts: skipLimitPagination(),
        getMindwareservicesCustomers: skipLimitPagination(),
        getMindwareservicesContracts: skipLimitPagination(),
        getMindwareservicesOrders: skipLimitPagination(),
        getCmsContractManager: skipLimitPagination(),
      },
    },
  },
  addTypename: false,
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: cache,
  defaultOptions: {
    watchQuery: {
      getCmsFunctionGetMyUser: 'cache-only',
      getMindwareservicesBasket: 'cache-only',
    },
  },
});

export default client;

function skipLimitPagination(keyArgs = false) {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const existingDocs = existing?.documents ?? [];
      const incomingDocs = incoming?.documents ?? [];

      if (existingDocs.length === 0 || incomingDocs.length === 0) {
        return incoming;
      }

      const merged = existingDocs ? existingDocs.slice(0) : [];
      if (args) {
        const { skip = 0 } = args;
        if (skip === 0) {
          return incoming;
        }

        for (let i = 0; i < incomingDocs.length; ++i) {
          merged[skip + i] = incomingDocs[i];
        }
      } else {
        merged.push.apply(merged, incomingDocs);
      }

      return { ...incoming, documents: merged };
    },
  };
}
