import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { accessTokenStorage, deleteTokensOnLogout, refreshTokenStorage } from '../tokenStorage';
import { useMutation, useQuery } from '@apollo/client';
import { LOGOUT } from '../graphql/mutations';
import { GET_MY_USER } from '../graphql/queries/UserQueries';
import client from '../graphql/apolloClient';
import { GET_BASKET } from '../graphql/queries/BasketQueries';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [logout, { called: logoutCalled }] = useMutation(LOGOUT);
  const { called: calledGetBasket } = useQuery(GET_BASKET);
  const basketData = client.readQuery({
    query: GET_BASKET,
  });
  const myUserData = client.readQuery({
    query: GET_MY_USER,
  });

  useEffect(() => {
    if (logoutCalled) {
      const tempToken = Boolean(accessTokenStorage.read());

      deleteTokensOnLogout();

      if (tempToken) {
        client.clearStore().finally(() => {
          navigate('/login');
        });
      } else {
        navigate('/distributor/dashboard');
      }
    }
  }, [logoutCalled]);

  const onMobileNavOpen = () => setMobileNavOpen(true);
  const onMobileNavClose = () => setMobileNavOpen(false);
  const handleLogout = () => logout();

  const handleGoToCart = () => navigate('/reseller/basket');

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onMobileNavOpen={onMobileNavOpen}
        user={
          myUserData?.getCmsFunctionGetMyUser?.result?.length > 0
            ? myUserData?.getCmsFunctionGetMyUser?.result[0]
            : undefined
        }
        goToCart={handleGoToCart}
        itemsInCart={basketData?.getMindwareservicesBasket?.products?.length}
      />
      <DashboardSidebar
        onMobileClose={onMobileNavClose}
        openMobile={isMobileNavOpen}
        onLogout={handleLogout}
        user={
          myUserData?.getCmsFunctionGetMyUser?.result?.length > 0
            ? myUserData?.getCmsFunctionGetMyUser?.result[0]
            : undefined
        }
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
