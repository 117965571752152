import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import removeEmptyStrings from '../../utils/removeEmpty';

const SettingsMargin = ({ onChangeMargin, currentMargin, loading, ...rest }) => {
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        margin: currentMargin ?? '',
      }}
      validationSchema={Yup.object().shape({
        margin: Yup.number()
          .min(0, 'Margin percentage must be positive')
          .required('Margin is required'),
      })}
      onSubmit={(values, formikHelpers) => {
        const cleanValues = removeEmptyStrings(values);
        onChangeMargin(cleanValues);
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Card {...rest}>
            <CardHeader subheader="Update margin" title="Margin" />
            <Divider />
            <CardContent>
              <TextField
                error={Boolean(touched.margin && errors.margin)}
                fullWidth
                helperText={touched.margin && errors.margin}
                label="Margin"
                margin="normal"
                name="margin"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.margin}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button color="primary" variant="contained" type="submit" disabled={loading}>
                Update
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SettingsMargin.propTypes = {
  onChangeMargin: PropTypes.func.isRequired,
  currentMargin: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};
SettingsMargin.defaultProps = {
  loading: false,
};

export default SettingsMargin;
