import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Decimal from 'decimal.js';

const SettingsUserListResults = ({
  users,
  count,
  onDelete,
  onAdd,
  skip,
  setSkip,
  limit,
  setLimit,
  ...rest
}) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [rowActionAnchorEl, setRowActionAnchorEl] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleActionMenuClose = () => {
    setSelectedUser(null);
    setRowActionAnchorEl(null);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    handleActionMenuClose();
  };

  const handleActionMenuOpen = (event, values) => {
    setSelectedUser(values);
    setRowActionAnchorEl(event.currentTarget);
  };

  const handleRowDelete = () => {
    setConfirmationDialogOpen(true);
  };

  const handleDeleteUser = () => {
    handleConfirmationDialogClose();
    onDelete(selectedUser);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  return (
    <>
      <Card {...rest}>
        <CardHeader
          subheader="User management"
          title="Users"
          action={
            <Button variant={'outlined'} onClick={onAdd}>
              Add User
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Registration Date</TableCell>
                    {/*<TableCell padding="checkbox" />*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    ? users.slice(skip, skip + limit)?.map((user) => (
                        <TableRow hover key={user._id}>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {user.fullname}
                            </Typography>
                          </TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{moment(user.createdAt).format('DD/MM/YYYY')}</TableCell>
                          {/*<TableCell padding="checkbox">*/}
                          {/*  <IconButton*/}
                          {/*    size="small"*/}
                          {/*    onClick={(event) => handleActionMenuOpen(event, user)}*/}
                          {/*  >*/}
                          {/*    <MoreVert />*/}
                          {/*  </IconButton>*/}
                          {/*</TableCell>*/}
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={pageNumber(skip, limit)}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardContent>
      </Card>
      {/*<Menu*/}
      {/*  id="simple-menu"*/}
      {/*  anchorEl={rowActionAnchorEl}*/}
      {/*  keepMounted*/}
      {/*  open={Boolean(rowActionAnchorEl)}*/}
      {/*  onClose={handleActionMenuClose}*/}
      {/*>*/}
      {/*  <MenuItem onClick={handleRowDelete}>Delete</MenuItem>*/}
      {/*</Menu>*/}
      {/*<Dialog*/}
      {/*  open={confirmationDialogOpen}*/}
      {/*  onClose={handleConfirmationDialogClose}*/}
      {/*  aria-labelledby="alert-dialog-title"*/}
      {/*  aria-describedby="alert-dialog-description"*/}
      {/*>*/}
      {/*  <DialogTitle id="alert-dialog-title">Delete user?</DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*    <DialogContentText id="alert-dialog-description">*/}
      {/*      Are you sure you want to delete this user?*/}
      {/*    </DialogContentText>*/}
      {/*  </DialogContent>*/}
      {/*  <DialogActions>*/}
      {/*    <Button onClick={handleConfirmationDialogClose} color="primary" autoFocus>*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*    <Button onClick={handleDeleteUser} color="error">*/}
      {/*      Delete*/}
      {/*    </Button>*/}
      {/*  </DialogActions>*/}
      {/*</Dialog>*/}
    </>
  );
};

SettingsUserListResults.propTypes = {
  users: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
};

SettingsUserListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default SettingsUserListResults;
