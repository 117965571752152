import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Decimal from 'decimal.js';
import moment from 'moment';
import ContractDetailsDialog from './ContractDetailsDialog';
import { Visibility } from '@material-ui/icons';

const ContractListResults = ({
  contracts,
  opportunity,
  count,
  skip,
  setSkip,
  limit,
  setLimit,
  setSelected,
  selected,
  finalizeAddSeatsOrder,
  loadingFinalizeAddSeatsOrder,
  finalizeRenewOrder,
  loadingFinalizeRenewOrder,
  getOpportunities,
  loadingGetOpportunities,
  ...rest
}) => {
  const [contractDetails, setContractDetails] = useState(null);
  const [openContractDetails, setOpenContractDetails] = useState(false);

  const handleShowContractDetails = (event, contract) => {
    if (contract) {
      setContractDetails(contract);
      setOpenContractDetails(true);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    const newSkip = new Decimal(newPage).mul(limit).trunc().toNumber();
    setSkip(newSkip);
  };

  const pageNumber = () => {
    return new Decimal(skip).div(limit).trunc().toNumber();
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {setSelected ? <TableCell padding={'checkbox'} /> : null}
                  <TableCell>Contract Number</TableCell>
                  <TableCell>Reseller Name</TableCell>
                  <TableCell>Reseller CSN</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer CSN</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Activation Date</TableCell>
                  <TableCell>Expiration Date</TableCell>
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts
                  ? contracts.slice(skip, skip + limit).map((contract) => (
                      <TableRow hover key={contract._id}>
                        {setSelected ? (
                          <TableCell padding={'checkbox'}>
                            <Checkbox
                              checked={contract._id === selected?._id}
                              onChange={(event) =>
                                setSelected(event.target.checked ? contract : undefined)
                              }
                              onClick={(event) => event.stopPropagation()}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {contract.contractNumber}
                          </Typography>
                        </TableCell>
                        <TableCell>{contract.reseller?.fullname}</TableCell>
                        <TableCell>{contract.reseller?.CSN ?? '-'}</TableCell>
                        <TableCell>{contract.customer?.account?.name}</TableCell>
                        <TableCell>{contract.customer?.CSN ?? '-'}</TableCell>
                        <TableCell>{contract.products?.length ?? 0}</TableCell>
                        <TableCell>{moment(contract.activeFrom).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{moment(contract.expiresOn).format('DD/MM/YYYY')}</TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            sx={{ marginRight: 2 }}
                            onClick={(event) => handleShowContractDetails(event, contract)}
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={pageNumber(skip, limit)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <ContractDetailsDialog
        setOpen={setOpenContractDetails}
        open={openContractDetails}
        contract={contractDetails}
        opportunity={opportunity}
        finalizeAddSeatsOrder={finalizeAddSeatsOrder}
        loadingFinalizeAddSeatsOrder={loadingFinalizeAddSeatsOrder}
        finalizeRenewOrder={finalizeRenewOrder}
        loadingFinalizeRenewOrder={loadingFinalizeRenewOrder}
        getOpportunities={getOpportunities}
        loadingGetOpportunities={loadingGetOpportunities}
      />
    </>
  );
};

ContractListResults.propTypes = {
  contracts: PropTypes.array.isRequired,
  opportunity: PropTypes.object,
  count: PropTypes.number.isRequired,
  skip: PropTypes.number,
  setSkip: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  finalizeAddSeatsOrder: PropTypes.func,
  loadingFinalizeAddSeatsOrder: PropTypes.bool,
  finalizeRenewOrder: PropTypes.func,
  loadingFinalizeRenewOrder: PropTypes.bool,
  getOpportunities: PropTypes.func,
  loadingGetOpportunities: PropTypes.bool,
};

ContractListResults.defaultProps = {
  skip: 0,
  setSkip: () => {},
  limit: 10,
  setLimit: () => {},
};

export default ContractListResults;
