import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import BasketListResults from '../basket/BasketListResults';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import OrderListResults from '../order/OrderListResults';
import ContractAddSeatsDialog from './ContractAddSeatsDialog';
import ContractRenewalDialog from './ContractRenewalDialog';

const ContractDetailsDialog = ({
  open,
  setOpen,
  contract,
  opportunity,
  finalizeAddSeatsOrder,
  loadingFinalizeAddSeatsOrder,
  finalizeRenewOrder,
  loadingFinalizeRenewOrder,
  getOpportunities,
  loadingGetOpportunities,
}) => {
  const [addSeatsOpen, setAddSeatsOpen] = useState(false);
  const [renewalOpen, setRenewalOpen] = useState(false);

  useEffect(() => {
    if (opportunity) {
      handleClose();
      setRenewalOpen(true);
    }
  }, [opportunity]);

  const handleAddSeats = () => {
    handleClose();
    setAddSeatsOpen(true);
  };

  const handleGetOpportunities = () => {
    getOpportunities(contract?._id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Contract Details
          {contract?.contractNumber ? ` | ${contract?.contractNumber}` : ''}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant={'h5'} color={'textPrimary'}>
                Active from:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {moment(contract?.activeFrom).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant={'h5'} color={'textPrimary'}>
                Expires on:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {moment(contract?.expiresOn).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h4'} color={'textPrimary'}>
                    Reseller
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    value={contract?.reseller?.CSN ?? undefined}
                    margin="dense"
                    id="CSN"
                    label="CSN"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    value={contract?.reseller?.fullname ?? undefined}
                    margin="dense"
                    id="fullname"
                    label="Full Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    value={contract?.reseller?.email ?? undefined}
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h4'} color={'textPrimary'}>
                    Customer
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    value={contract?.customer?.CSN ?? undefined}
                    margin="dense"
                    id="CSN"
                    label="CSN"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <Typography variant={'h5'} color={'textPrimary'}>
                        Account
                      </Typography>
                      <TextField
                        value={contract?.customer?.account?.name ?? undefined}
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.altName ?? undefined}
                        margin="dense"
                        id="altName"
                        label="Alt Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.addressLine1 ?? undefined}
                        margin="dense"
                        id="addressLine1"
                        label="Address Line 1"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.addressLine2 ?? undefined}
                        margin="dense"
                        id="addressLine2"
                        label="Address Line 2"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.addressLine3 ?? undefined}
                        margin="dense"
                        id="addressLine3"
                        label="Address Line 3"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.city ?? undefined}
                        margin="dense"
                        id="city"
                        label="City"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.postalCode ?? undefined}
                        margin="dense"
                        id="postalCode"
                        label="Postal Code"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.stateProvinceCode ?? undefined}
                        margin="dense"
                        id="stateProvinceCode"
                        label="State Province Code"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.countryCode ?? undefined}
                        margin="dense"
                        id="countryCode"
                        label="Country Code"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        value={contract?.customer?.account?.phoneNumber ?? undefined}
                        margin="dense"
                        id="phoneNumber"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h4'} color={'textPrimary'}>
                    Products
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BasketListResults basketItems={contract?.products ?? []} />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h4'} color={'textPrimary'}>
                    Orders
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <OrderListResults
                    orders={contract?.orders ?? []}
                    count={contract?.orders?.length ?? 0}
                    disablePagination
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {finalizeAddSeatsOrder ? (
            <Button variant={'contained'} onClick={handleAddSeats}>
              Add Seats
            </Button>
          ) : null}
          {getOpportunities ? (
            <Button
              variant={'contained'}
              onClick={handleGetOpportunities}
              disabled={loadingGetOpportunities}
              endIcon={
                loadingGetOpportunities ? (
                  <CircularProgress color={'inherit'} size={16} />
                ) : undefined
              }
            >
              Get Opportunities
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <ContractAddSeatsDialog
        setOpen={setAddSeatsOpen}
        open={addSeatsOpen}
        contract={contract}
        finalizeOrder={finalizeAddSeatsOrder}
        finalizeLoading={loadingFinalizeAddSeatsOrder}
      />
      <ContractRenewalDialog
        setOpen={setRenewalOpen}
        open={renewalOpen}
        contractId={contract?._id}
        opportunity={opportunity}
        finalizeOrder={finalizeRenewOrder}
        finalizeLoading={loadingFinalizeRenewOrder}
      />
    </>
  );
};

ContractDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  contract: PropTypes.object,
  opportunity: PropTypes.object,
  finalizeAddSeatsOrder: PropTypes.func,
  loadingFinalizeAddSeatsOrder: PropTypes.bool,
  finalizeRenewOrder: PropTypes.func,
  loadingFinalizeRenewOrder: PropTypes.bool,
  getOpportunities: PropTypes.func,
  loadingGetOpportunities: PropTypes.bool,
};

export default ContractDetailsDialog;
