import React from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Sync } from '@material-ui/icons';
import getBase64 from '../../utils/getBase64';

const SettingsToolbar = ({
  onImportProducts,
  onAccountSync,
  onSubscriptionSync,
  importLoading,
  syncLoading,
  ...rest
}) => {
  const handleProductFileChange = (event) => {
    const files = event?.target?.files;
    if (files?.length > 0 && files[0]) {
      getBase64(files[0], onLoadBase64File);
    }
  };

  const onLoadBase64File = (fileString) => {
    onImportProducts(fileString);
  };

  return (
    <Box {...rest}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button sx={{ mx: 1 }} component="label" disabled={importLoading}>
          Import Products
          <input
            hidden
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleProductFileChange}
          />
        </Button>
        <ButtonGroup variant="contained" color="primary" disabled={syncLoading}>
          <Button onClick={onSubscriptionSync}>
            Subscription <Sync />
          </Button>
          <Button onClick={onAccountSync}>
            Account <Sync />
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

SettingsToolbar.propTypes = {
  onImportProducts: PropTypes.func.isRequired,
  onAccountSync: PropTypes.func.isRequired,
  onSubscriptionSync: PropTypes.func.isRequired,
  importLoading: PropTypes.bool,
  syncLoading: PropTypes.bool,
};

export default SettingsToolbar;
