export const usageTypes = {
  COMMERCIAL: 'Commercial',
  GENERIC: 'Generic',
  MISCELLANEOUS: 'Miscellaneous',
};

export const materialGroups = {
  MAINTENANCE: 'Maintenance',
  MEDIA: 'Media',
  SERVICES: 'Services',
  SUBSCRIPTION: 'Subscription',
};

export const materialTypes = {
  LICENSE_ACTIVATION: 'License Activation',
  NEW: 'New',
  RENEWAL: 'Renewal',
  RENEWAL_M2S: 'Renewal M2S',
  RENEWAL_M2S_SWITCH: 'Renewal M2S Switch from 1- or 2-Yr',
  SWITCH_CATEGORY_1: 'Switch from Category 1',
  SWITCH_CATEGORY_2: 'Switch from Category 2',
  SWITCH_M2S: 'Switch from M2S',
};

export const contractTerms = {
  YEAR_3: '3-Year',
  ANNUAL: 'Annual',
};
