import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import orderStatus from '../../utils/orderStatus';
import { debounce } from 'lodash';

const OrderListToolbar = ({
  onExport,
  onSearch,
  search,
  orderStatusFilter = Object.keys(orderStatus),
  setOrderStatusFilter,
  paidFilter,
  onMarkPaid,
  setPaidFilter,
  ...rest
}) => {
  const [startDateFilter, setStartDateFilter] = React.useState(null);
  const [endDateFilter, setEndDateFilter] = React.useState(null);

  const handleOrderStatusChange = (event) => {
    let newOrderStatusFilter = orderStatusFilter.slice();
    if (event.target.checked) {
      newOrderStatusFilter = [...newOrderStatusFilter, event.target.name];
    } else {
      newOrderStatusFilter = newOrderStatusFilter.filter((item) => item !== event.target.name);
    }

    if (
      newOrderStatusFilter.length === Object.keys(orderStatus).length ||
      newOrderStatusFilter.length === 0
    ) {
      setOrderStatusFilter(undefined);
    } else {
      setOrderStatusFilter(newOrderStatusFilter);
    }
  };

  const handlePaidChange = (event) => {
    if (paidFilter === undefined) {
      setPaidFilter(
        event.target.checked ? event.target.name === 'paid' : event.target.name !== 'paid',
      );
    } else {
      setPaidFilter(undefined);
    }
  };

  const onChangeSearch = useMemo(
    () =>
      debounce((event) => {
        onSearch(event?.target?.value);
      }, 500),
    [onSearch],
  );

  return (
    <Box {...rest}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button color="primary" variant="contained" onClick={onExport}>
          Export
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search customer ID"
                  type="search"
                  onChange={onChangeSearch}
                />
              </Grid>
              {/*<Grid item xl={2} sm={6} xs={12}>*/}
              {/*  <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
              {/*    <DatePicker*/}
              {/*      label="Start date"*/}
              {/*      value={startDateFilter}*/}
              {/*      onChange={(newValue) => {*/}
              {/*        setStartDateFilter(newValue);*/}
              {/*      }}*/}
              {/*      renderInput={({ InputProps, ...rest }) => {*/}
              {/*        const { endAdornment, ...restInputProps } = InputProps;*/}
              {/*        return (*/}
              {/*          <TextField*/}
              {/*            {...rest}*/}
              {/*            InputProps={{*/}
              {/*              ...restInputProps,*/}
              {/*              endAdornment: (*/}
              {/*                <>*/}
              {/*                  {startDateFilter ? (*/}
              {/*                    <InputAdornment*/}
              {/*                      position="end"*/}
              {/*                      onClick={() => setStartDateFilter(null)}*/}
              {/*                    >*/}
              {/*                      <Clear fontSize={'small'} />*/}
              {/*                    </InputAdornment>*/}
              {/*                  ) : null}*/}
              {/*                  {endAdornment}*/}
              {/*                </>*/}
              {/*              ),*/}
              {/*            }}*/}
              {/*            fullWidth*/}
              {/*            helperText={null}*/}
              {/*          />*/}
              {/*        );*/}
              {/*      }}*/}
              {/*      components={{ OpenPickerIcon: Today }}*/}
              {/*    />*/}
              {/*  </LocalizationProvider>*/}
              {/*</Grid>*/}
              {/*<Grid item xl={2} sm={6} xs={12}>*/}
              {/*  <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
              {/*    <DatePicker*/}
              {/*      label="End date"*/}
              {/*      value={endDateFilter}*/}
              {/*      onChange={(newValue) => {*/}
              {/*        setEndDateFilter(newValue);*/}
              {/*      }}*/}
              {/*      renderInput={({ InputProps, ...rest }) => {*/}
              {/*        const { endAdornment, ...restInputProps } = InputProps;*/}
              {/*        return (*/}
              {/*          <TextField*/}
              {/*            {...rest}*/}
              {/*            InputProps={{*/}
              {/*              ...restInputProps,*/}
              {/*              endAdornment: (*/}
              {/*                <>*/}
              {/*                  {endDateFilter ? (*/}
              {/*                    <InputAdornment*/}
              {/*                      position="end"*/}
              {/*                      onClick={() => setEndDateFilter(null)}*/}
              {/*                    >*/}
              {/*                      <Clear fontSize={'small'} />*/}
              {/*                    </InputAdornment>*/}
              {/*                  ) : null}*/}
              {/*                  {endAdornment}*/}
              {/*                </>*/}
              {/*              ),*/}
              {/*            }}*/}
              {/*            fullWidth*/}
              {/*            helperText={null}*/}
              {/*          />*/}
              {/*        );*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </LocalizationProvider>*/}
              {/*</Grid>*/}
              <Grid item sm={6} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel>Order status</FormLabel>
                  <FormGroup row>
                    {Object.entries(orderStatus).map(([key, value], index) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={orderStatusFilter?.includes(key)}
                            onChange={handleOrderStatusChange}
                            name={key}
                          />
                        }
                        label={value}
                        sx={{ textTransform: 'capitalize' }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                {onMarkPaid ? (
                  <FormControl component="fieldset">
                    <FormLabel>Paid</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={paidFilter === true || paidFilter === undefined}
                            onChange={handlePaidChange}
                            name="paid"
                          />
                        }
                        label={'Paid'}
                        sx={{ textTransform: 'capitalize' }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={paidFilter === false || paidFilter === undefined}
                            onChange={handlePaidChange}
                            name="notPaid"
                          />
                        }
                        label={'Not Paid'}
                        sx={{ textTransform: 'capitalize' }}
                      />
                    </FormGroup>
                  </FormControl>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

OrderListToolbar.propTypes = {
  onExport: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  search: PropTypes.string,
  orderStatusFilter: PropTypes.array,
  setOrderStatusFilter: PropTypes.func,
  onMarkPaid: PropTypes.func,
  paidFilter: PropTypes.bool,
  setPaidFilter: PropTypes.func,
};

export default OrderListToolbar;
