import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Autocomplete,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { contractTerms, materialGroups, materialTypes, usageTypes } from '../../utils/productEnums';

const ProductListToolbar = ({
  onSearch,
  onSearchProductLines,
  productLines,
  onSetProductLineFilter,
  onSetUsageTypeFilter,
  onSetMaterialGroupFilter,
  onSetMaterialTypeFilter,
  onSetContractTermFilter,
  onSetMultiuserFilter,
  ...rest
}) => {
  const onChangeSearch = useMemo(
    () =>
      debounce((event) => {
        onSearch(event?.target?.value);
      }, 500),
    [onSearch],
  );

  const onChangeSearchProductLines = useMemo(
    () =>
      debounce((event) => {
        onSearchProductLines(event?.target?.value);
      }, 500),
    [onSearchProductLines],
  );

  return (
    <Box {...rest}>
      <Box sx={{ mt: 2 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xl={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search product"
                  variant="outlined"
                  type="search"
                  onChange={onChangeSearch}
                />
              </Grid>
              <Grid item xl={3} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  filterOptions={(x) => x}
                  options={productLines}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Line"
                      fullWidth
                      variant="outlined"
                      onChange={onChangeSearchProductLines}
                    />
                  )}
                  onChange={(event, newValue) => onSetProductLineFilter(newValue?.code)}
                />
              </Grid>
              <Grid item xl={3} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={Object.values(usageTypes)}
                  renderInput={(params) => (
                    <TextField {...params} label="Usage Type" fullWidth variant="outlined" />
                  )}
                  onChange={(event, newValue) => onSetUsageTypeFilter(newValue)}
                />
              </Grid>
              <Grid item xl={3} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={Object.values(materialGroups)}
                  renderInput={(params) => (
                    <TextField {...params} label="Material Group" fullWidth variant="outlined" />
                  )}
                  onChange={(event, newValue) => onSetMaterialGroupFilter(newValue)}
                />
              </Grid>
              <Grid item xl={3} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={Object.values(materialTypes)}
                  renderInput={(params) => (
                    <TextField {...params} label="Material Type" fullWidth variant="outlined" />
                  )}
                  onChange={(event, newValue) => onSetMaterialTypeFilter(newValue)}
                />
              </Grid>
              <Grid item xl={3} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={Object.values(contractTerms)}
                  renderInput={(params) => (
                    <TextField {...params} label="Contract Term" fullWidth variant="outlined" />
                  )}
                  onChange={(event, newValue) => onSetContractTermFilter(newValue)}
                />
              </Grid>
              <Grid item xl={3} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Multiuser" fullWidth variant="outlined" />
                  )}
                  onChange={(event, newValue) => onSetMultiuserFilter(newValue?.value)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

ProductListToolbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSearchProductLines: PropTypes.func.isRequired,
  productLines: PropTypes.array.isRequired,
  onSetProductLineFilter: PropTypes.func.isRequired,
  onSetUsageTypeFilter: PropTypes.func.isRequired,
  onSetMaterialGroupFilter: PropTypes.func.isRequired,
  onSetMaterialTypeFilter: PropTypes.func.isRequired,
  onSetContractTermFilter: PropTypes.func.isRequired,
  onSetMultiuserFilter: PropTypes.func.isRequired,
};

export default ProductListToolbar;
