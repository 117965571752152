import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import Decimal from 'decimal.js';
import { isNil } from 'lodash';

const BasketAddDialog = ({ open, setOpen, onAdd, onCheckout, onUpdate, product, quantity }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <Formik
        initialValues={{
          product: product?._id,
          quantity: quantity ? quantity : 1,
        }}
        validationSchema={Yup.object().shape({
          quantity: Yup.number().positive().integer().required(),
        })}
        onSubmit={(values) => {
          handleClose();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Buy Product</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <DialogContentText>Name</DialogContentText>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant={'body1'}>{product?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <DialogContentText>Price</DialogContentText>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant={'body1'}>${product?.costPrice}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched?.quantity && errors?.quantity)}
                    fullWidth
                    helperText={touched.quantity && errors.quantity}
                    label="Quantity"
                    margin="normal"
                    name="quantity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.quantity}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <DialogContentText>Total Price</DialogContentText>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant={'body1'}>
                    $
                    {!isNil(product?.costPrice) &&
                    product?.costPrice !== '' &&
                    !isNil(values?.quantity) &&
                    values?.quantity !== ''
                      ? new Decimal(product.costPrice).mul(values.quantity).toNumber()
                      : null}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {onUpdate ? (
                <Button
                  variant="contained"
                  type={'submit'}
                  color="primary"
                  onClick={() => onUpdate(values)}
                >
                  Update
                </Button>
              ) : null}
              {onAdd ? (
                <Button
                  variant="contained"
                  type={'submit'}
                  color="primary"
                  autoFocus
                  disabled={!values?.quantity || values?.quantity < 1}
                  onClick={() => onAdd(values)}
                >
                  Add to Basket
                </Button>
              ) : null}
              {onCheckout ? (
                <Button
                  variant="contained"
                  type={'submit'}
                  color="primary"
                  disabled={!values?.quantity || values?.quantity < 1}
                  onClick={() => onCheckout(values)}
                >
                  Go to Checkout
                </Button>
              ) : null}
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

BasketAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onCheckout: PropTypes.func,
  onUpdate: PropTypes.func,
  product: PropTypes.object,
  quantity: PropTypes.number,
};

export default BasketAddDialog;
