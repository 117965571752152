import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import removeEmptyStrings from '../../utils/removeEmpty';
import CountrySelect from '../CountrySelect';
import validator from 'validator';

const CustomerAddDialog = ({ open, setOpen, onSubmit }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        usingCSN: false,
        CSN: '',
        account: {
          name: '',
          altName: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          city: '',
          postalCode: '',
          stateProvinceCode: '',
          countryCode: '',
          phoneNumber: '',
        },
      }}
      validationSchema={Yup.object().shape({
        usingCSN: Yup.boolean(),
        CSN: Yup.string().when('usingCSN', (usingCSN, field) =>
          usingCSN ? field.max(10).required('CSN is required') : field,
        ),
        account: Yup.object().shape({
          name: Yup.string().max(100).required('Name is required'),
          altName: Yup.string().max(35).required('Alt name is required'),
          addressLine1: Yup.string().max(35).required('Address is required'),
          addressLine2: Yup.string().max(35),
          addressLine3: Yup.string().max(35),
          city: Yup.string().max(35).required('City is required'),
          postalCode: Yup.string().max(35).required('Postal code is required'),
          stateProvinceCode: Yup.string().max(35),
          countryCode: Yup.string().max(2).required('Country code is required'),
          phoneNumber: Yup.string()
            .max(35)
            .test('phone-validation', 'Phone number is not valid', function (value) {
              if (!value) return false;
              return validator.isMobilePhone(value);
            }),
        }),
      })}
      onSubmit={(values, formikHelpers) => {
        const cleanValues = removeEmptyStrings(values);
        handleClose();
        if (cleanValues?.usingCSN) {
          onSubmit({
            CSN: cleanValues?.CSN,
            shipTo: cleanValues?.account,
          });
        } else {
          onSubmit({
            account: cleanValues?.account,
            shipTo: cleanValues?.account,
          });
        }
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={(event, reason) => {
              if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
              handleClose();
              resetForm();
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
            scroll="paper"
          >
            <DialogTitle>Add Customer</DialogTitle>
            <DialogContent dividers>
              <FormControlLabel
                control={
                  <Switch
                    checked={values?.usingCSN ?? false}
                    onChange={(event) => setFieldValue('usingCSN', event?.target?.checked ?? false)}
                  />
                }
                label="Add using CSN"
              />
              {values?.usingCSN ? (
                <TextField
                  error={Boolean(touched?.CSN && errors?.CSN)}
                  fullWidth
                  helperText={touched?.CSN && errors?.CSN}
                  label="CSN*"
                  margin="normal"
                  name="CSN"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values?.CSN}
                  variant="outlined"
                />
              ) : null}
              <Divider />
              <TextField
                error={Boolean(touched?.account?.name && errors?.account?.name)}
                fullWidth
                helperText={touched?.account?.name && errors?.account?.name}
                label="Name*"
                margin="normal"
                name="account.name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.account?.altName && errors?.account?.altName)}
                fullWidth
                helperText={touched?.account?.altName && errors?.account?.altName}
                label="Alt Name*"
                margin="normal"
                name="account.altName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.altName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.account?.addressLine1 && errors?.account?.addressLine1)}
                fullWidth
                helperText={touched?.account?.addressLine1 && errors?.account?.addressLine1}
                label="Address Line 1*"
                margin="normal"
                name="account.addressLine1"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.addressLine1}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.account?.addressLine2 && errors?.account?.addressLine2)}
                fullWidth
                helperText={touched?.account?.addressLine2 && errors?.account?.addressLine2}
                label="Address Line 2"
                margin="normal"
                name="account.addressLine2"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.addressLine2}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.account?.addressLine3 && errors?.account?.addressLine3)}
                fullWidth
                helperText={touched?.account?.addressLine3 && errors?.account?.addressLine3}
                label="Address Line 3"
                margin="normal"
                name="account.addressLine3"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.addressLine3}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.account?.city && errors?.account?.city)}
                fullWidth
                helperText={touched?.account?.city && errors?.account?.city}
                label="City*"
                margin="normal"
                name="account.city"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.city}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched?.account?.postalCode && errors?.account?.postalCode)}
                fullWidth
                helperText={touched?.account?.postalCode && errors?.account?.postalCode}
                label="Postal Code*"
                margin="normal"
                name="account.postalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.postalCode}
                variant="outlined"
              />
              <TextField
                error={Boolean(
                  touched?.account?.stateProvinceCode && errors?.account?.stateProvinceCode,
                )}
                fullWidth
                helperText={
                  touched?.account?.stateProvinceCode && errors?.account?.stateProvinceCode
                }
                label="State Province Code"
                margin="normal"
                name="account.stateProvinceCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.stateProvinceCode}
                variant="outlined"
              />
              <CountrySelect
                fullWidth
                onChange={(e, value) => setFieldValue('account.countryCode', value)}
                value={values?.account?.countryCode}
                TextFieldProps={{
                  error: Boolean(touched?.account?.countryCode && errors?.account?.countryCode),
                  helperText: touched?.account?.countryCode && errors?.account?.countryCode,
                  label: 'Country Code*',
                  margin: 'normal',
                  name: 'account.countryCode',
                  onBlur: handleBlur,
                  variant: 'outlined',
                  type: 'text',
                }}
                disableClearable
              />
              <TextField
                error={Boolean(touched?.account?.phoneNumber && errors?.account?.phoneNumber)}
                fullWidth
                helperText={touched?.account?.phoneNumber && errors?.account?.phoneNumber}
                label="Phone Number"
                margin="normal"
                name="account.phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values?.account?.phoneNumber}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={isSubmitting} onClick={handleSubmit} color="primary" autoFocus>
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

CustomerAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomerAddDialog;
