import React from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import removeEmptyStrings from '../../utils/removeEmpty';

const SettingsPassword = ({ onChangePassword, loading, ...rest }) => {
  return (
    <Formik
      validateOnMount
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      }}
      validationSchema={Yup.object().shape({
        oldPassword: Yup.string().max(255).required('Password is required'),
        newPassword: Yup.string()
          .max(255)
          .required('Password is required')
          .notOneOf(
            [Yup.ref('oldPassword')],
            'New password cannot be the same as the old password',
          ),
        newPasswordConfirm: Yup.string()
          .max(255)
          .required('Password is required')
          .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
      })}
      onSubmit={(values, formikHelpers) => {
        const cleanValues = removeEmptyStrings(values);
        onChangePassword(cleanValues);
        formikHelpers.resetForm();
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Card {...rest}>
            <CardHeader subheader="Update password" title="Password" />
            <Divider />
            <CardContent>
              <TextField
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                fullWidth
                helperText={touched.oldPassword && errors.oldPassword}
                label="Old Password"
                margin="normal"
                name="oldPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.oldPassword}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.newPassword && errors.newPassword)}
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                label="New Password"
                margin="normal"
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.newPassword}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.newPasswordConfirm && errors.newPasswordConfirm)}
                fullWidth
                helperText={touched.newPasswordConfirm && errors.newPasswordConfirm}
                label="Confirm New Password"
                margin="normal"
                name="newPasswordConfirm"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.newPasswordConfirm}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button color="primary" variant="contained" type="submit" disabled={loading}>
                Update
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SettingsPassword.propTypes = {
  onChangePassword: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SettingsPassword.defaultProps = {
  loading: false,
};

export default SettingsPassword;
