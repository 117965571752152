import { gql } from '@apollo/client';

export const FINALIZE_ORDER = gql`
  mutation FinalizeOrder($params: FinalizeOrderRequest) {
    FinalizeOrder(params: $params) {
      _id
      processingMessage
      distributorMargin
      distributorCost
      cost
      status
      transactionId
      customer {
        _id
        CSN
        account {
          name
          altName
          addressLine1
          addressLine2
          addressLine3
          city
          postalCode
          stateProvinceCode
          countryCode
          phoneNumber
        }
      }
      contractManager {
        _id
        firstName
        lastName
        email
        countryCode
        language
      }
      products {
        quantity
        product {
          _id
          name
          materialDescription
          isRenewal
          canOrder
          discontinued
          isMultiuser
          isSwitch
          materialNumber
          comingFrom
          costPrice
          discount
          orderingSystem
          startDate
          endDate
          productGroup
          productLineDescription
          usageType
          materialType
          contractTerm
          renewalRef
          renewalTripleRef
          validFrom
          createdAt
        }
        actualPrice
        price
      }
      createdAt
    }
  }
`;

export const FINALIZE_ADD_SEATS_ORDER = gql`
  mutation FinalizeAddSeatsOrder($params: FinalizeAddSeatsOrderRequest) {
    FinalizeAddSeatsOrder(params: $params) {
      _id
      processingMessage
      distributorMargin
      distributorCost
      cost
      status
      transactionId
      customer {
        _id
        CSN
        account {
          name
          altName
          addressLine1
          addressLine2
          addressLine3
          city
          postalCode
          stateProvinceCode
          countryCode
          phoneNumber
        }
      }
      contractManager {
        _id
        firstName
        lastName
        email
        countryCode
        language
      }
      products {
        quantity
        product {
          _id
          name
          materialDescription
          isRenewal
          canOrder
          discontinued
          isMultiuser
          isSwitch
          materialNumber
          comingFrom
          costPrice
          discount
          orderingSystem
          startDate
          endDate
          productGroup
          productLineDescription
          usageType
          materialType
          contractTerm
          renewalRef
          renewalTripleRef
          validFrom
          createdAt
        }
        actualPrice
        price
      }
      createdAt
    }
  }
`;

export const FINALIZE_RENEW_ORDER = gql`
  mutation FinalizeRenewOrder($params: FinalizeRenewalRequest) {
    FinalizeRenewal(params: $params) {
      _id
      processingMessage
      distributorMargin
      distributorCost
      cost
      status
      transactionId
      customer {
        _id
        CSN
        account {
          name
          altName
          addressLine1
          addressLine2
          addressLine3
          city
          postalCode
          stateProvinceCode
          countryCode
          phoneNumber
        }
      }
      contractManager {
        _id
        firstName
        lastName
        email
        countryCode
        language
      }
      products {
        quantity
        product {
          _id
          name
          materialDescription
          isRenewal
          canOrder
          discontinued
          isMultiuser
          isSwitch
          materialNumber
          comingFrom
          costPrice
          discount
          orderingSystem
          startDate
          endDate
          productGroup
          productLineDescription
          usageType
          materialType
          contractTerm
          renewalRef
          renewalTripleRef
          validFrom
          createdAt
        }
        actualPrice
        price
      }
      createdAt
    }
  }
`;

export const SUBMIT_ORDER = gql`
  mutation SubmitOrder($order: String!) {
    SubmitOrder(order: $order) {
      result
    }
  }
`;

export const SUBMIT_ADD_SEATS_ORDER = gql`
  mutation SubmitAddSeatsOrder($order: String!) {
    SubmitAddSeatOrder(order: $order) {
      result
    }
  }
`;

export const SUBMIT_RENEW_ORDER = gql`
  mutation SubmitRenewOrder($order: String!) {
    RenewProducts(order: $order) {
      result
    }
  }
`;

export const MARK_PAID_ORDER = gql`
  mutation MarkOrderAsPaid($ids: [String]!, $paid: Boolean!) {
    MarkOrderAsPaid(ids: $ids, paid: $paid) {
      orders {
        _id
      }
    }
  }
`;
