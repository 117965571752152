import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, List, Typography } from '@material-ui/core';
import {
  BarChartOutlined,
  ReceiptLongOutlined,
  PeopleOutlined,
  SettingsOutlined,
  LogoutOutlined,
  Inventory2Outlined,
  ReceiptOutlined,
  AssignmentOutlined,
} from '@material-ui/icons';
import NavItem from './NavItem';
import { sessionAccessTokenStorage } from '../tokenStorage';

export const DRAWER_WIDTH = 256;

const distributorItems = [
  {
    href: '/distributor/dashboard',
    icon: BarChartOutlined,
    title: 'Dashboard',
  },
  {
    href: '/distributor/resellers',
    icon: PeopleOutlined,
    title: 'Resellers',
  },
  {
    href: '/distributor/orders',
    icon: ReceiptLongOutlined,
    title: 'Orders',
  },
  {
    href: '/distributor/settings',
    icon: SettingsOutlined,
    title: 'Settings',
  },
];

const resellerItems = [
  {
    href: '/reseller/dashboard',
    icon: BarChartOutlined,
    title: 'Dashboard',
  },
  {
    href: '/reseller/products',
    icon: Inventory2Outlined,
    title: 'Products',
  },
  {
    href: '/reseller/orders',
    icon: ReceiptOutlined,
    title: 'Orders',
  },
  {
    href: '/reseller/customers',
    icon: PeopleOutlined,
    title: 'Customers',
  },
  {
    href: '/reseller/contracts',
    icon: AssignmentOutlined,
    title: 'Contracts',
  },
  {
    href: '/reseller/settings',
    icon: SettingsOutlined,
    title: 'Settings',
  },
];

const logoutItem = {
  icon: LogoutOutlined,
  title: 'Logout',
};

const DashboardSidebar = ({ onMobileClose, openMobile, onLogout, user }) => {
  const location = useLocation();

  const items = useMemo(() => {
    const userType = user?.userType?.toLowerCase();
    switch (userType) {
      case 'distributor':
        return distributorItems;
      case 'reseller':
        return resellerItems;
      default:
        return [];
    }
  }, [user?.userType]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        {sessionAccessTokenStorage.read() ? (
          <Typography color="primary" variant="h6">
            IMPERSONATION
          </Typography>
        ) : null}
        <Typography color="textPrimary" variant="h5">
          {user?.fullname}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ p: 2 }}>
        <List>
          <NavItem
            onClick={onLogout}
            key={logoutItem.title}
            title={logoutItem.title}
            icon={logoutItem.icon}
          />
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Box display={{ lg: 'none' }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onLogout: PropTypes.func,
  user: PropTypes.object,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  onLogout: () => {},
  openMobile: false,
};

export default DashboardSidebar;
