import { gql } from '@apollo/client';

export const GET_CONTRACTS = gql`
  query GetMindwareservicesContracts(
    $skip: Number!
    $limit: Number!
    $customerId: String
    $searchString: String
    $sort: [String]
  ) {
    getMindwareservicesContracts(
      skip: $skip
      limit: $limit
      customerId: $customerId
      searchString: $searchString
      sort: $sort
    ) {
      documentsCount
      documents {
        _id
        customer {
          _id
          CSN
          account {
            name
            altName
            addressLine1
            addressLine2
            addressLine3
            city
            postalCode
            stateProvinceCode
            countryCode
            phoneNumber
          }
          shipTo {
            name
            altName
            addressLine1
            addressLine2
            addressLine3
            city
            postalCode
            stateProvinceCode
            countryCode
            phoneNumber
          }
        }
        reseller {
          _id
          CSN
          fullname
          email
        }
        products {
          quantity
          product {
            _id
            name
            materialDescription
            isRenewal
            canOrder
            discontinued
            isMultiuser
            isSwitch
            materialNumber
            comingFrom
            costPrice
            discount
            orderingSystem
            startDate
            endDate
            productGroup
            productLineDescription
            usageType
            materialType
            contractTerm
            renewalRef
            renewalTripleRef
            validFrom
            createdAt
          }
          sku
          seats
          serial_number
        }
        contractNumber
        activeFrom
        expiresOn
        orders {
          _id
          processingMessage
          distributorMargin
          distributorCost
          cost
          status
          transactionId
          customer {
            _id
            CSN
            account {
              name
              altName
              addressLine1
              addressLine2
              addressLine3
              city
              postalCode
              stateProvinceCode
              countryCode
              phoneNumber
            }
            shipTo {
              name
              altName
              addressLine1
              addressLine2
              addressLine3
              city
              postalCode
              stateProvinceCode
              countryCode
              phoneNumber
            }
          }
          contractManager {
            _id
            firstName
            lastName
            email
            countryCode
            language
          }
          products {
            quantity
            product {
              _id
              name
              materialDescription
              isRenewal
              canOrder
              discontinued
              isMultiuser
              isSwitch
              materialNumber
              comingFrom
              costPrice
              discount
              orderingSystem
              startDate
              endDate
              productGroup
              productLineDescription
              usageType
              materialType
              contractTerm
              renewalRef
              renewalTripleRef
              validFrom
              createdAt
            }
            actualPrice
            price
          }
          createdAt
        }
      }
    }
  }
`;

export const GET_CONTRACT_OPPORTUNITIES = gql`
  query GetContractsOpportunities($contract: String!) {
    GetContractsOpportunities(contract: $contract) {
      opportunity {
        opportunity_number
        order_number
        products {
          serial_number
          renewal_sku
          sku_description
          renewed_quantity
          quantity
          seats
          deployment
        }
      }
    }
  }
`;

export const GET_CONTRACT_MANAGERS = gql`
  query getMindwareservicesCustomersContractManager(
    $customerId: String!
    $searchString: String
    $skip: Number!
    $limit: Number!
    $sort: [String]
  ) {
    getMindwareservicesCustomersContractManager(
      customerId: $customerId
      skip: $skip
      limit: $limit
      searchString: $searchString
      sort: $sort
    ) {
      contractManagersCount
      contractManagers {
        _id
        firstName
        lastName
        email
        countryCode
        language
        createdAt
      }
    }
  }
`;
