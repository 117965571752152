import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const ProductDetailsDialog = ({ open, setOpen, product, onBuy }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
        handleClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Product Details
        {product?.materialNumber ? ` | ${product?.materialNumber}` : ''}
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          value={product?.name ?? undefined}
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.productGroup ?? undefined}
          margin="dense"
          id="productGroup"
          label="Product Group"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.productLineDescription ?? undefined}
          margin="dense"
          id="productLineDescription"
          label="Product Line Description"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.materialNumber ?? undefined}
          margin="dense"
          id="materialNumber"
          label="Material Number"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.materialGroup ?? undefined}
          margin="dense"
          id="materialGroup"
          label="Material Group"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.materialDescription ?? undefined}
          margin="dense"
          id="materialDescription"
          label="Material Description"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.materialType ?? undefined}
          margin="dense"
          id="materialType"
          label="Material Type"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.usageType ?? undefined}
          margin="dense"
          id="usageType"
          label="Usage Type"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.contractTerm ?? undefined}
          margin="dense"
          id="contractTerm"
          label="Contract Term"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.isMultiuser ?? false}
          margin="dense"
          id="isMultiuser"
          label="Multiuser"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.isRenewal ?? false}
          margin="dense"
          id="isRenewal"
          label="Renewal"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.renewalRef ?? undefined}
          margin="dense"
          id="renewalRef"
          label="Renewal Ref"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.renewalTripleRef ?? undefined}
          margin="dense"
          id="renewalTripleRef"
          label="Renewal Triple Ref"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.isSwitch ?? false}
          margin="dense"
          id="isSwitch"
          label="Switch"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.comingFrom ?? undefined}
          margin="dense"
          id="comingFrom"
          label="Coming From"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.startDate ?? undefined}
          margin="dense"
          id="startDate"
          label="Start Date"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.endDate ?? undefined}
          margin="dense"
          id="endDate"
          label="End Date"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.validFrom ?? undefined}
          margin="dense"
          id="validFrom"
          label="Valid From"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.costPrice ?? undefined}
          margin="dense"
          id="costPrice"
          label="Cost Price"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.discount ?? undefined}
          margin="dense"
          id="discount"
          label="Discount"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.orderingSystem ?? undefined}
          margin="dense"
          id="orderingSystem"
          label="Ordering System"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.canOrder ?? false}
          margin="dense"
          id="canOrder"
          label="Can Order"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={product?.discontinued ?? false}
          margin="dense"
          id="discontinued"
          label="Discontinued"
          type="text"
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {onBuy ? (
          <Button variant="contained" color="primary" onClick={() => onBuy(product)}>
            Buy
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

ProductDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onBuy: PropTypes.func,
  product: PropTypes.object,
};

export default ProductDetailsDialog;
